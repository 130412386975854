<template>

  <div>
    <div class="header-modal">
      <div class="container-header-modal">
        <p class="montserrat-bold white mt-3">Subir imagen</p>
        <a @click="closeModal">
          <div class="button-close has-text-right mr-6 mt-5">
            <svg
              width="16"
              height="16"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M25 1L1 25"
                stroke="#fff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L25 25"
                stroke="#fff"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>

    <div id="edit_avatar" class="container-edit-photo">
      <br>
      <h1 class="montserrat-medium mt-6 mb-1 gray-medium">Sube una foto nueva</h1>
      <section v-if="files.length < 2">
      <!-- <section v-if="files.length < 2 && status !== 'Verified'"> -->
        <div class="mt-10">
          <div class="is-flex is-flex-direction-column">
            <!-- Preview Image -->
            <img class="img-avatar-preview" v-if="image" :src="image" alt="card_image">
            <!-- Drop image -->
            <b-field
              v-if="showFileDrop"
              class="mt-5">
              <b-upload
                v-model="dropFiles"
                multiple
                @change.native="handleImage"
                drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <img
                      class="mb-2 mt-6"
                      width="25px"
                      src="https://emituscdn.com/Upload-icon.svg">
                    <p
                      class="paragraph-content gray-medium">
                      Arrastra tu foto aquí o haz click para subirla
                      <br>
                      (.jpg .jpeg. or .png)</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <!-- C2A Cancell-->
            <div class="is-flex mt-5 is-justify-content-space-between">
              <b-button
                v-if="image"
                @click="fileCancel"
                class="button-secundary-small text-basier"
                >
                <span class="">Cancel</span>
              </b-button>
              <!-- C2A OK-->
              <b-button
                v-if="image"
                @click="uploadImage"
                class="button-primary-medium text-basier"
                >
                <span class="text-basier">Guardar</span>
              </b-button>
              <div v-if="loader">
                <Loading/>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>

  </div>




</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Media from '@/services/MediaService'
import ProfileService from '@/services/ProfileService'
import LocationService from '@/services/LocationService'

export default {
  components: {
    Loading
  },
  name: 'EditAvatar',

  data () {
    return {
      user: null,
      files: [],
      dropFiles: [],
      image: '',
      status: null,
      refusedReason: null,
      loader: false
    }
  },

  // COMPUTED

  computed: {
    showFileDrop: function () {
      if (this.dropFiles.length > 0) {
        return false
      } else {
        return true
      }
    }

  },

  // WATCH

  watch: {
    dropFiles: function () {
      if (this.dropFiles.length > 0) {
        this.handleImage()
      }
    }
  },

  // METHODS
  methods: {
    // close modal
    closeModal () {
      if (window.location.pathname.split('/')[2] === 'lugares' && window.location.pathname.split('/')[3] === undefined) {
        this.$store.commit('closeModal2')
      } else {
        this.$store.commit('closeModal')
      }
    },


    // Handle Image
    async handleImage () {
      const selectedImage = this.dropFiles[0]
      // const selectedImage = e.target.files[0] // get first file
      this.createBase64Image(selectedImage)
    },
    async createBase64Image (fileObject) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(fileObject)
    },

    // file cancell
    async fileCancel () {
      this.dropFiles = []
      this.image = ''
    },

    // Upload image
    async uploadImage () {
      this.loader = true
      // const idToken = await firebase.auth().currentUser.getIdToken(true)

      // Upload image to Cloudinary
      const response = await Media.uploadImageCloudinary(this.image)
        .catch(err => {
          // console.log('----> Error uploadImageCloudinary', err)
          this.danger('Algo salió mal')
          this.loader = false
        })

      // console.log('----> Response', response)

      let baseUrl = ''

      if (response.data.width > response.data.height) {
        // horizontal
        baseUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_scale,q_auto:best,h_520/c_crop,g_west,h_520,w_520,x_15/${response.data.public_id}.jpg`

      } else {
        baseUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_scale,q_auto:best,w_520/c_crop,g_north,h_520,w_520,y_15/${response.data.public_id}.jpg`

      }

      // console.log('baseURl', baseUrl)

      if (response.status === 200 && this.$store.state.user.typeofuser == 'Admin' && window.location.pathname.split('/')[2] === 'lugares') {
        // CASE A) Upload image New Lugar
        if (window.location.pathname.split('/')[3] === undefined) {
          const idToken = await firebase.auth().currentUser.getIdToken(true)

          if (this.$store.state.picture_public_id && this.$store.state.picture_public_id !== undefined) {
            let picture_public_id_string = this.$store.state.picture_public_id
            // console.log('picture_public_id_string', picture_public_id_string)
            let picture_public_id_wok = picture_public_id_string.substr(10)              
          
            await Media.deleteImageCloudinary(idToken, picture_public_id_wok)
          }

          this.$store.commit('addPicture', baseUrl)
          this.$store.commit('addPicturePublicId', response.data.public_id)
          this.$store.commit('closeModal2')



        // CASE B) Update image Lugar
        } else {
          const id_location = window.location.pathname.split('/')[3]
          const idToken = await firebase.auth().currentUser.getIdToken(true)
          const updatedLocation = await LocationService.UpdateLocation( idToken, {
            picture: baseUrl,
            picture_public_id: response.data.public_id
          }, id_location)
  
          if (updatedLocation.status === 200) {

            if (this.$store.state.currentLocation.picture_public_id !== undefined) {
              let currentLocation_public_id_string = this.$store.state.currentLocation.picture_public_id
              // console.log('currentLocation_public_id_string', currentLocation_public_id_string)
              let currentLocation_public_id_wok = currentLocation_public_id_string.substr(10)              
           
              await Media.deleteImageCloudinary(idToken, currentLocation_public_id_wok)
            }


            this.success('Guardado')
            this.$store.commit('updateLocations')
            this.$store.commit('updateLocation', window.location.pathname.split('/')[3])

            this.loader = false
            this.$store.commit('closeModal')
          }
        }
        
      // CASE C) Upload image Profile
      } else if (response.status === 200) {
        const idToken = await firebase.auth().currentUser.getIdToken(true)

        let newProfile = null
        
        if (this.$store.state.user.typeofuser == 'Admin') {
          newProfile = await ProfileService.updateProfileAdmin( idToken, this.$store.state.currentProfileId, {
            avatar: baseUrl,
            avatar_public_id: response.data.public_id
          })
          if (newProfile.status === 201) {
            this.success('Guardado')
            this.$store.commit('updateCurrentProfile', window.location.pathname.split('/')[3])
            this.$store.commit('updateAllProfilesDB')
          }
        } else {
          newProfile = await ProfileService.updateProfile( idToken, {
            avatar: baseUrl,
            avatar_public_id: response.data.public_id,
            _id: this.$store.state.currentProfileId
          })
          if (newProfile.status === 201) {
            this.success('Guardado')

            if (this.$store.getters.currentProfile.avatar_public_id !== undefined) {
              
              let avatar_public_id_string = this.$store.getters.currentProfile.avatar_public_id
              // console.log('avatar_public_id_string', avatar_public_id_string)
              let avatar_public_id_wok = avatar_public_id_string.substr(10)
  
  
              await Media.deleteImageCloudinary(idToken, avatar_public_id_wok)

            }
            
            await firebase.auth().currentUser.getIdToken(true)
              .then(async idToken => {
                await ProfileService.getCurrentProfiles(idToken)
                  .then(response => {
                    if (response.status === 200) {
                      // console.log('01', response)
                      this.$store.commit('updateProfiles', response.data)
                    }
                  })
                  .catch(()=>{})
              })
        }



        }
   
        this.loader = false
        this.$store.commit('closeModal')
      }

      

    },

    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    }

  }

}
</script>

<style scoped>

#edit_avatar {
  width: 90%;
  max-width: 345px;
  margin: 9px auto;
}

#edit_avatar .upload .upload-draggable {
  border: 1px solid #b5b5b5;
  border-radius: 2px;
  padding: 15px !important;
}
#edit_avatar .upload .upload-draggable .section {
  position:relative
}
#edit_avatar .upload .upload-draggable .section:before, #edit_avatar .upload .upload-draggable .section:after, #edit_avatar .upload .upload-draggable .section>:first-child:before, #edit_avatar .upload .upload-draggable .section>:first-child:after {
  position:absolute; content:' ';
  width:30px; height: 30px;
  border-color: #F29713; /* or whatever colour */
  border-style:solid; /* or whatever style */
}
#edit_avatar .upload .upload-draggable .section:before {
  top:0;left:0;border-width: 3px 0 0 3px
}
#edit_avatar .upload .upload-draggable .section:after {
  top:0;right:0;border-width: 3px 3px 0 0
}
#edit_avatar .upload .upload-draggable .section>:first-child:before {
  bottom:0;right:0;border-width: 0 3px 3px 0
}
#edit_avatar .upload .upload-draggable .section>:first-child:after {
  bottom:0;left:0;border-width: 0 0 3px 3px
}

.img-avatar-preview {
  object-fit: cover;
  width: 100%;
  height: 280px;
}

.header-modal {
  width: 100%;
  height: 63px;
  background-color: #3C3C3C;
}

.container-header-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

@media (min-width: 768px) {
  .img-avatar-preview {
    object-fit: cover;
    width: 100%;
    height: 280px;
  }
}
</style>
