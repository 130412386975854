import { render, staticRenderFns } from "./navbarMentor.vue?vue&type=template&id=40d4bfaa&scoped=true"
import script from "./navbarMentor.vue?vue&type=script&lang=js"
export * from "./navbarMentor.vue?vue&type=script&lang=js"
import style0 from "./navbarMentor.vue?vue&type=style&index=0&id=40d4bfaa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d4bfaa",
  null
  
)

export default component.exports