<template>
  <div id="configuration-admin">
    <div class="container-places">
      <div class="title-configuration">
        <h2 class="montserrat-bold white">Configuración</h2>
      </div>
    </div>

    <!--Section skill Level-->
    <div class="container-option-configuration" v-if="false">
      <div class="container-content-configuration">
        <h3 class="montserrat-bold black">Niveles</h3>
        <form>
          <!--Skill Level-->
          <b-field class="mt-5 max-campos" label="Nivel">
            <b-taginput
              v-model="skilllevels"
              ellipsis
              icon="label"
              placeholder="Agregar un nivel"
            >
            </b-taginput>
          </b-field>
        </form>

        <!-- button save skill level-->

        <button
          @click="UpdateSkillLevel"
          class="mt-5 button-primary-extra-medium"
        >
          Guardar
        </button>
      </div>
    </div>

    <!--SECTION REFILL-->
    <div class="container-option-configuration">
      <div class="container-content-configuration">
        <h3 class="montserrat-bold black">Recargas</h3>

        <!-- List places of classes -->

        <div class="container-list" v-for="(discount, idx) in $store.state.discountVols"
        :key="idx">
          <div class="content-list">
            <div class="info-complementary-item">
              <div class="letf">
                <!--AMOUNT REFILL-->
                <p class="montserrat-bold black">{{discount.amount}}€</p>

                <div class="rigth is-flex">
                  <!--PERCENTAGE -->
                  <p v-if="false" class="mr-4">{{discount.discount}}%</p>

                  <!-- TOTAL AMOUNT --->
                  <p v-if="false" class="montserrat-bold black">{{CalculateRecharge(discount.amount, discount.discount)}}€</p>
                </div>
              </div>

              <div class="buttons-place">

               
                <!-- <button @click="updateRecarga(discount._id)" class="button-medium-stroke">
                  Editar
                </button> -->

                <!-- <button
                @click="$store.commit('openModal', 'newrecarga')"
                class="button-medium-stroke">
                  Editar
                </button> -->
                <button class="button-medium-stroke ml-4" v-on:click="deleteRecarga(discount._id)" >Borrar</button>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6">

          <a
            class="montserrat-semibold text-underline orange link is-size-extra-text"
            @click="$store.commit('openModal', 'newrecarga')">
            + Agregar nueva recarga
          </a
          >
          <!--
          <a
            class="montserrat-semibold text-underline orange link is-size-extra-text"
            @click.prevent="newDiscount">
            + Agregar nueva recarga</a
          >-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configurationService from "../../services/ConfigurationService";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "Configuracion",
  data() {
    return {
      skilllevels: [],

      mensajeNamePlace: "",
      mensajeLength: "",
      mensajeLatitude: "",
    };
  },

  mounted() {
    this.$store.commit(
      "updateCurrentProfile",
      window.location.pathname.split("/")[2]
    );

    setTimeout(() => {
      this.skilllevels = this.$store.state.skilllevels;
    }, 500);
  },

  // METHODS
  methods: {
    async UpdateSkillLevel() {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      const response = await configurationService.updateSkillLevel(idToken, {
        skills: this.skilllevels,
      });
      if (response.status == 200) {
        // console.log(response.data)
        this.$store.commit("updateSkills");
        this.success("Guardado");
      }
    },
    CalculateRecharge(value, percentage) {
      let recharge = (1 + percentage / 100) * value
      return recharge.toFixed(2)
    },
    ValidityNamePlace() {
      if (!this.name) {
        this.mensajeNamePlace = "Cuál es el nombre del curso?";
        this.messageTypeNamePlace = "is-primary";
        this.valid = false;
      } else {
        this.mensajeName = "";
      }
    },

    ValidityLength() {
      if (!this.name) {
        this.mensajeLength = "Cuál es la longitud?";
        this.messageTypeLength = "is-primary";
        this.valid = false;
      } else {
        this.mensajeName = "";
      }
    },

    ValidityLatitude() {
      if (!this.name) {
        this.mensajeLatitude = "Cuál es la latitud?";
        this.messageTypeLoatitude = "is-primary";
        this.valid = false;
      } else {
        this.mensajeName = "";
      }
    },

    // Success Toast
    success(msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: "is-top",
        type: "is-success",
      });
    },
    // Danger Toast
    danger(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-primary",
      });
    },
    async deleteRecarga (id_discount) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await configurationService.deleteRecarga(idToken, id_discount)
      if (response.status == 200) {
        this.success('Recarga eliminado')
        this.$store.commit('updateDiscounts')
      }
    },
    newDiscount() {
      this.$store.commit('openModal', 'newrecarga')
    },
    updateRecarga (id_discount) {
      this.$router.push(`/admin/configuracion/recarga/${id_discount}/`)
    },
  },
};
</script>

<style scoped>

#configuration-admin {
  background: #fafafa;
  height: 100%;
  padding-bottom: 30px;
}

.container-header-configuration {
  height: 98px;
  margin-top: 64px;
  background-color: #393939;
}

.container-header-configuration h2 {
  font-size: 24px;
}

.title-configuration {
  width: 80%;
  margin: auto;
  max-width: 1240px;
  padding-top: 25px;
}

.container-option-configuration {
  width: 95%;
  max-width: 882px;
  background-color: #fff;
  border: 1px solid #ededed;
  margin: auto;
  padding-bottom: 30px;
  margin-top: 20px;
}

.container-content-configuration {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.container-content-configuration h3 {
  font-size: 20px;
  margin-bottom: 35px;
}

.max-campos {
  max-width: 430px;
}

.container-list {
  display: flex;
  background-color: #f5f3f3;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  align-items: center;
  padding: 13px 12px 12px 13px;
}

.content-list {
  display: flex;
  width: 100%;
}

.info-complementary-item {
  display: flex;
  width: 95%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.letf {
  display: flex;
  justify-content: space-between;
  width: 48%;
}

.upload-image {
  width: 90%;
  max-width: 612px;
  height: 262px;
  border: 1px solid #dbdbdb;
  border-radius: 5;
}

.container-button-upload {
  width: 193px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 85px;
}

.container-button-upload p {
  font-size: 14px;
}

.link:focus, .link:hover, .link:active {
  color: #fcc97d;
}

@media only screen and (max-width: 800px) {
  .letf {
    width: 38%;
  }
}

@media only screen and (max-width: 670px) {
  .info-complementary-item {
    display: block;
  }

  .letf {
    width: 95%;
    margin: auto;
    margin-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dddddd;
  }

  .buttons-place {
    width: 95%;
    margin: auto;
    margin-top: 15px;

  }

  .button-medium-stroke {
    width: 115px;
  }

}
</style>