<template>
  <div id="student">
    <div class="container-student">
      <p v-if="section === 'mis-perfiles'" class="montserrat-medium black title-step">4. Elige un estudiante</p>
      <p v-if="section === 'cursos-activos'" class="montserrat-medium black title-step">2. Elige un estudiante</p>


      <div class="list-student">
        <div
          v-for="(profile, index) in profiles"
          :key="index"
          class="content-student is-flex is-align-items-center"
          v-on:click="saveStudentSelection(profile)"
          v-bind:class="{
            bookingSelection:
              $store.state.weeklyBookingState.student &&
              $store.state.weeklyBookingState.student == profile,
            disabled:
              false//$store.getters.currentClass.bookings.some(booking => booking.profile_id === profile._id),
          }"
        >
          <!-- AVATAR STUDENT -->
          <img v-bind:src="profile.avatar" />

          <!-- NAME STUDENT -->
          <p v-if="!(profile.residente && $store.state.weeklyBookingState.course.residentDiscount > 0 || profile.federado && $store.state.weeklyBookingState.course.federadoDiscount)" class="montserrat-semibold black ml-3">
            {{ profile.firstname + " " + profile.lastname }}
          </p>

          <!-- NAME STUDENT -->
          <p v-else class="montserrat-semibold black ml-3">
            {{ profile.firstname + " " + profile.lastname }} {{'(' + calculateDiscount($store.state.weeklyBookingState.course, profile) + '% descuento)'}}         
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.$store.commit('updateCourseClasses', this.$store.state.weeklyBookingState.course._id)
  },
  data() {
    return {
      section: window.location.pathname.split('/')[2],
      profiles: this.$store.state.profiles,
    };
  },
  methods: {
    saveStudentSelection(selectedStudent) {
      let weeklyBookingState = this.$store.state.weeklyBookingState
      weeklyBookingState.student = selectedStudent
      this.$store.commit("updateWeeklyBookingState", weeklyBookingState)
      this.$store.commit("incrementWeeklyBookingStep")
    },
    calculateDiscount(course, profile) {
      if (profile.residente && course.residentDiscount > 0) return course.residentDiscount
      if (profile.federado && course.federadoDiscount > 0) return course.federadoDiscount
      return 0
    }
  },
};
</script>

<style scoped>
.disabled {
  background-color: #d6d6d6 !important;
  pointer-events: none;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.title-step {
  font-size: 15px;
  margin-top: 60px;
}

.list-student {
  margin-bottom: 20px;
}

.content-student {
  background-color: #f5f3f3;
  width: 100%;
  max-width: 380px;
  height: 64px;
  border-radius: 2px;
  border: none;
  color: #000;
  font-family: "Montserrat SemiBold";
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  margin-top: 25px;
}

.content-student:hover {
  background-color: #fbdfb6;
}

.content-student img {
  width: 34px;
  height: 34px;
  border-radius: 100px;
}

.content-student p {
  font-size: 15px;
}
</style>