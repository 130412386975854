<template>
  <div id="booking">
    
    <div class="header-modal">

      <a @click="closeModalR">
        <div class="title-modal">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M15 8H1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 15L1 8L8 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <!-- STEP NAME -->
          <div class="step montserrat-semibold black ml-4">
            {{this.bookingStepTitles[this.$store.state.step-1]}}
          </div>
        </div>
      </a>

      <div class="buttons-modal">

        <!-- BUTTON PREV BOOKING -->
        <div v-if="section === 'mis-perfiles' || this.$store.state.step > 3" class="prev" @click="previousStep">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="34.3862" y="34.386" width="34" height="34" rx="7" transform="rotate(-180 34.3862 34.386)" fill="#EEEEEE"/>
            <path d="M11.3862 16.386L20.3862 11.1898L20.3862 21.5821L11.3862 16.386Z" fill="#111747" fill-opacity="0.32"/>
          </svg>
        </div>

        <!-- BUTTON NEXT BOOKING -->
        <div v-if="Object.values(this.$store.state.reservationState)[this.$store.state.step] != null" class="next  ml-2" @click="nextStep">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.38623" y="0.385986" width="34" height="34" rx="7" fill="#EEEEEE"/>
            <path d="M23.3862 18.386L14.3862 23.5821L14.3862 13.1898L23.3862 18.386Z" fill="#111747" fill-opacity="0.32"/>
          </svg>
        </div>
        <div v-else class="next  ml-2">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.38623" y="0.385986" width="34" height="34" rx="7" fill="#EEEEEE" fill-opacity="0.45"/>
            <path d="M23.3862 18.386L14.3862 23.5821L14.3862 13.1898L23.3862 18.386Z" fill="#111747" fill-opacity="0.06"/>
          </svg>
        </div>
      </div>
    </div>
    
   
    <div class="container-step has-text-left mt-5">
      <Level
        v-if="this.$store.state.step === 1"
      />
      <Course
        v-if="this.$store.state.step === 2"
      />
      <Date 
        v-if="this.$store.state.step === 3"
      />
      <Student 
        v-if="this.$store.state.step === 4"
      />
      <Confirmation 
        v-if="this.$store.state.step === 5"
      />
      <Success
        v-if="this.$store.state.reservationSuccessfull && this.$store.state.step === 6"
        />
      <!-- 
      <Rejected
        v-if="this.$store.state.reservationSuccessfull && this.$store.state.step === 6"
        />
      <Refills
        v-if="this.$store.state.reservationSuccessfull && this.$store.state.step === 6"
        /> -->
    </div>
  </div>


</template>

<script>

import ProfileService from '@/services/ProfileService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Level from '@/components/booking/level.vue'
import Course from '@/components/booking/course.vue'
import Date from '@/components/booking/date.vue'
import Student from '@/components/booking/student.vue'
import Confirmation from '@/components/booking/confirmation.vue'
import Success from '@/components/modals/booking-success.vue'

export default {
  name: 'NewBooking',
  components: {
    Level,
    Course,
    Date,
    Student,
    Confirmation,
    Success,
  },
  data () {
    return {
      section: window.location.pathname.split('/')[2],
      bookingStepTitles: ['Nivel', 'Curso', 'Fecha y hora', 'Estudiante', 'Verificación'],
      authUser: this.$store.state.authUser,
      valid: true,
      generalError: null,
      loader: false,
      bindProps: {
        mode: 'international',
        preferredCountries: ['ES'],
        onlyCountries: [],
        ignoredCountries: []
      },
    }
  },

  // METHODS
  methods: {

    // Close modal
    closeModalR () {
      this.$store.commit('closeModalR')
      this.$store.commit('updateBookingStep', 1)
      this.$store.commit('updateReservation', {})
    },
    nextStep () {
      if (this.$store.state.step === 3 && this.$store.state.reservationState.with_bono) this.$store.commit('updateBookingStep', 4)
      this.$store.commit('incrementBookingStep')
    },
    previousStep () {
      if (this.$store.state.step === 5 && this.$store.state.reservationState.with_bono) this.$store.commit('updateBookingStep', 4)
      this.$store.commit('decrementBookingStep')
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}


</script>


<style scoped>

.header-modal {
  width: 85%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-modal {
  display: flex;
  align-items: center;
}

.buttons-modal {
  display: flex;
}

.step {
  font-size: 15px;
}

/* #Booking */

#booking {
  background-color: #ffffff;
  width: 50%;
  min-width: 440px;
  max-width: 550px;
  position: absolute;
  right: 0;
  height: 100vh;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  overflow: auto;
}

#booking input:-webkit-autofill,
#booking input:-webkit-autofill:hover,
#booking input:-webkit-autofill:active,
#booking input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #555555 !important;
}
#booking .has_required {
  text-decoration: line-through;
  color:#6e3dea;
}
#booking form {
    margin-top: 30px;
    width: 90%;
    margin: auto;
    max-width: 360px;
}

#booking .title-header-section-create-account {
  margin-top: 35px;
  margin-bottom: 45px;
  margin-left: 5%;
}

#booking .container-step {
  width: 78%;
  margin: auto;
}

#booking input, #booking select {
    height: 45px !important;
}

#booking input::placeholder {
  font-family: 'Nunito' !important;
}

#booking input:hover {
  border-color: #EEEEEE;
}


@media only screen and (max-width: 441px) {
  #booking {
    width: 100%;
    height: 160vh;
    min-width: 356px;
    max-width: 550px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .header-modal {
    width: 90%;
  }

  #booking .container-step {
    width: 85%;
    margin: auto;
  }
}

</style>
