<template>
  <div id="panel">
    <div id="panel_container">
      
      <div id="profiles_container">
        <div
          v-for="(profile, idx) in $store.state.profiles"
          :key="idx"
          class="profile_card_container"
        >
          <div
            class="profile_card"
            @click.prevent="goToProfile(profile._id)"
          >
            <div class="container-profile-card is-flex is-justify-content-space-between mt-1">
              <div class="is-flex is-align-items-center">
                <img
                  class="is-rounded image-avatar-panel"
                  :src="profile.avatar"
                >
              
                <div class="name-user-container">
                  <p 
                    class="montserrat-bold black name-user">
                    {{profile.firstname}} {{profile.lastname}}
                  </p>
                  <p 
                    class="status-student OpenSans-Bold orange">{{profile.status === 'active' ? 'ACTIVO' : profile.status === 'inactive' ? 'INACTIVO' : 'REVISIÓN' }}</p>
                </div>
              </div>

              <div class="go-profile">
                <div class="button ml-3">
                  <svg width="11" height="20" viewBox="0 0 11 20" fill="none">
                    <path d="M1 19L10 10L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="new_profile_card_container">
          <div
            class="new_profile_card  black"
            @click.prevent="newprofile"
          >
            <p class="montserrat-semibold">+ Agregar nuevo estudiante</p>
          </div>
        </div>

      </div>

    </div>


    <!-- <button
      @click="modalReserva"
      class="boton-reservar"
    >
      Reservar Clase
    </button> -->


  </div>
</template>

<script>


export default {
  name: 'Panel',
  components: {
  },
  data () {
    return {
    }
  },

  methods: {
    // Go to profile selected
    goToProfile (id) {
      this.$router.push(`/panel/${id}/perfil`)
    },

    // New Profile
    newprofile () {
      this.$store.commit('openModal', 'newprofile')
    },

    // Reservar
    modalReserva () {
      this.$store.commit('openModalR', 'newbooking')
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  }
}
</script>

<style scoped>

.boton-reservar {
  -webkit-box-shadow: 8px -2px 24px 5px rgba(202,202,202,0.87); 
  box-shadow: 8px -2px 24px 5px rgba(202,202,202,0.87);
  background-color: #F29713;
  color: #fff;
  position: fixed;
  bottom: 28px;
  right: 15px;
  width: 227px;
  height: 57px;
  border: none;
  font-family: "Montserrat Medium";
  font-size: 16px;
  border-radius: 5px;
}

.button-reservar:hover, .button-reseervar:active, .button-reservar:focus {
  background: #f7a62c !important;
  transform: scale(1.04);
  color: #ffff;
}

#panel {
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #FAFAFA;
  height: 100vh;
  padding-bottom: 30px;
}

#panel_container {
  max-width: 800px;
  width: 70%;
  
}

#profiles_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}


.profile_card_container {
  width: 90%;
}

.profile_card {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 90%;
  margin: 0px 20px 15px 20px;
  padding: 15px 7px 7px 15px;
  min-height: 90px;
  border-radius: 2px;
}

.image-avatar-panel {
  width: 50px;
  height: 50px;
}

.name-user {
  font-size: 15px;
}

.status-student {
  font-size: 13px;
}

.go-profile {
  border-left: 1px solid #ECECEC;
  width: 10%;
}

.button {
  border: none !important;
}

.new_profile_card_container {
  width: 90%;
}

.new_profile_card {
  width: 90%;
  background-color: #fff;
  margin: 0px 20px 15px 20px;
  min-height: 90px;
  border: 1.9px dashed #000;
  border-radius: 2px;
}

.new_profile_card p{
  font-size: 14px;
  margin-top: 30px;
  margin-left: 25px;
}

.name-user-container {
  margin-left: 10px;
}


@media only screen and (max-width: 638px) {

  .boton-reservar {
    -webkit-box-shadow: 8px -2px 24px 5px rgba(202,202,202,0.87); 
    box-shadow: 8px -2px 24px 5px rgba(202,202,202,0.87);
    background-color: #F29713;
    color: #fff;
    position: fixed;
    bottom: 28px;
    right: 15px;
    width: 170px;
    height: 45px;
    border: none;
    font-family: "Montserrat Medium";
    font-size: 14px;
    border-radius: 5px;
  }

  #panel_container {
    width: 100%;
  }

  #profiles_container {
    padding-top: 15px;
  }

  .profile_card {
   width: 100%;
   margin: auto;
   margin-top: 10px;
   padding: 6px 7px 7px 15px;
   min-height: 63px;
  }

  .image-avatar-panel {
    width: 36px;
    height: 36px;
  }

  .button {
    margin-left: 1px !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }

  .go-profile {
    width: 9%;
  }

  .new_profile_card {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    min-height: 63px;
  }

  .name-user {
    font-size: 14px;
  }

  .new_profile_card p {
    margin-top: 19px;
    font-size: 12px;
  }

  .name-user-container {
    margin-left: 12px;
  } 

  .status-student {
    font-size: 12px;
  }
}

@media only screen and (max-width: 890px) {
  .profile_card {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    padding: 7px 19px 7px 15px;
  }

  .new_profile_card {
    width: 100%;
    margin: auto;
    margin-top: 20px;
  }

  #profiles_container {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}



</style>