<template>
  <div class="cursos-panel">
    <div id="cursos" class="cursos">
      <div class="content-course">
        <span class="black montserrat-bold title-course">Mis</span>
        <span class="black m-left montserrat-bold title-course"
          >Bonos</span
        >

        <div class="cursosContainer">
          <div
            v-for="(bono, index) in $store.state.mybonos"
            :key="index"
            class="cursoBox"
          >
            <div class="content-box-course">
            
              <div class="center">
                <span class="montserrat-bold black is-size-medium-text">{{
                  bono.course_id.name
                }}</span>

                <div class="mt-2">
                  <span class="montserrat-bold is-size-text-normal"
                    >Estudiante:
                  </span>
                  <span class="montserrat is-size-text-normal">{{
                    bono.profile_id.firstname + ' ' + bono.profile_id.lastname
                    }}</span
                  >
                  <span class="montserrat-bold is-size-text-normal ml-3"
                    >Clases usadas:</span
                  >
                  <span class="montserrat is-size-text-normal">
                    {{bono.bookings.length}}/{{bono.max_bookings}}</span
                  >
                </div>

              </div>

              <button
                :class="bono.bookings.length === bono.max_bookings ? 'button-primary-orange-small-disabled mleft-button' : 'button-primary-orange-small mleft-button'"
                @click.prevent="modalReserva(bono)"
                :disabled="bono.bookings.length === bono.max_bookings ? true : false"
              >
                Reservar clase
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'MisBonos',
  methods: {
    // Reservar
    modalReserva (bono) {
      const reservation = {
        course: bono.course_id,
        student: bono.profile_id,
        with_bono: true,
        price: 0
      }
      this.$store.commit('updateCurrentCourse', bono.course_id._id)
      this.$store.commit('updateReservation', reservation)
      this.$store.commit('incrementBookingStep')
      this.$store.commit('incrementBookingStep')
      this.$store.commit('openModalR', 'newbooking')
    },
  }
}
</script>

<style scoped>
.cursos-panel {
  margin-top: 65px;
}

/* COURSE */

.content-course {
  padding-top: 95px;
  width: 70%;
  margin: auto;
}

.cursosContainer {
  margin-top: 65px;
}

.paragraph-text {
  margin-top: 25px;
  max-width: 658px;
  line-height: 25px;
  opacity: 0.7;
}

.title-course {
  font-size: 25px;
}

.cursos {
  width: 100%;
  height: 100vh;
  background-color: #FAFAFA;
  padding-bottom: 100px;
}

.cursoBox {
  width: 100%;
  background-color: #fff;
  border: 1px solid #EDEDED;
  margin-top: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.content-box-course {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.image-place-course {
  object-fit: cover;
}

.image-place-course img {
  min-width: 120px;
  max-width: 130px;
  border-radius: 2px;
}

.center {
  max-width: 63%;
  min-width: 60%;
  margin-left: 17px;
  margin-right: 17px;
}

.m-left {
  margin-left: 10px;
}

.mobile-text {
  font-size: 13px;
  opacity: 0.6;
}

@media only screen and (max-width: 890px) {
  .content-course {
    padding-top: 80px;
    width: 85%;
  }

  .paragraph-text {
    max-width: 542px;
  }

  .content-box-course {
    width: 95%;
  }

  .center {
    max-width: 58%;
    min-width: 57%;
  }
}

@media only screen and (max-width: 732px) {
  
  .image-place-course {
    display: none;
  }

  .center {
    max-width: 66%;
    min-width: 68%;
  }

  .paragraph-text {
    max-width: 474px;
  }
}

@media only screen and (max-width: 564px) {
  .content-box-course {
    display: block;
  }

  .center {
    max-width: 90%;
  }

  .mleft-button {
    margin-left: 15px;
    margin-top: 15px;
  }

  .content-course {
    padding-top: 42px;
  }
}
</style>