<template>
  <div id="detail-class">
    <div class="header-modal">
      <div class="container-header is-flex">

        <!-- NAME COURSE -->
        <p class="montserrat-bold white title-course">
          {{classCourse.name}}
        </p>

        <!-- BUTTON CLOSE MODAL -->
        <div
          class="close-modal"
          @click="$store.commit('closeModal')">
          <svg
            class="button-close"
            width="18"
            height="18"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 2L2 17"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 2L17 17"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      
    </div>

    <!-- DATE CLASS -->
    <div class="date-class">
      <div class="container-date-class">
        <div class="square"></div>

        <div class="ml-4">
          <!-- DATE -->
          <span class="montserrat-semibold black">
              {{selectedClass.startclass | toDay}} - </span>

          <!-- HOUR  -->
          <span class="ml-2 montserrat-semibold black">
            {{formatHours(selectedClass.startclass, classCourse.startcourse)}} a {{formatHours(selectedClass.endclass, classCourse.startcourse)}}</span>
        </div>
      </div>
    </div>

    <!-- CONTENT CLASS -->
    <div class="info-class">
      <div class="container-info-class">
        <div class="content-class border">
          <!-- STUDENTS -->
          <div class="n-students">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M9.61946 3.67814C9.61946 5.15723 8.42042 6.35628 6.94132 6.35628C5.46223 6.35628 4.26318 5.15723 4.26318 3.67814C4.26318 2.19904 5.46223 1 6.94132 1C8.42042 1 9.61946 2.19904 9.61946 3.67814Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 13.0002L0.250309 12.9787C0.244494 13.1813 0.320894 13.3776 0.462114 13.5229C0.603335 13.6682 0.797355 13.7502 1 13.7502L1 13.0002ZM13.0029 13.0002V13.7502C13.2055 13.7502 13.3995 13.6682 13.5407 13.5229C13.682 13.3776 13.7584 13.1813 13.7526 12.9787L13.0029 13.0002ZM1 13.7502H13.0029V12.2502H1V13.7502ZM7.00143 7.34863C9.78209 7.34863 12.1608 9.80459 12.2532 13.0218L13.7526 12.9787C13.6408 9.08675 10.7238 5.84863 7.00143 5.84863V7.34863ZM1.74969 13.0218C1.84205 9.80459 4.22077 7.34863 7.00143 7.34863V5.84863C3.27907 5.84863 0.36204 9.08675 0.250309 12.9787L1.74969 13.0218Z"
                fill="black"
              />
            </svg>

            <!-- STUDENTS INSCRIBED -->
            <span class="montserrat-medium black"> {{selectedClass.bookings.length}} / {{selectedClass.maxstudents}} estudiantes </span>
          </div>

          <!-- LEVEL -->
          <div class="level-course">
            <svg
              width="12"
              height="15"
              viewBox="0 0 12 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.75 14V5.875"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.875 14V1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 14V9.125"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <!-- <span class="montserrat-semibold black"> Nivel: </span>

            <span class="montserrat black ml-1"> {{classCourse.skilllevel}} </span> -->
          </div>
        </div>

        <div class="content-place">
          <!-- PLACE COURSE -->
          <div class="place-course">
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4545 6.72727C12.4545 11.1818 6.72727 15 6.72727 15C6.72727 15 1 11.1818 1 6.72727C1 5.20831 1.60341 3.75155 2.67748 2.67748C3.75155 1.60341 5.20831 1 6.72727 1C8.24624 1 9.70299 1.60341 10.7771 2.67748C11.8511 3.75155 12.4545 5.20831 12.4545 6.72727Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.72745 8.6363C7.78181 8.6363 8.63654 7.78157 8.63654 6.72721C8.63654 5.67284 7.78181 4.81812 6.72745 4.81812C5.67309 4.81812 4.81836 5.67284 4.81836 6.72721C4.81836 7.78157 5.67309 8.6363 6.72745 8.6363Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="montserrat-semibold black"> Lugar:</span>

            <!-- PLACE CLASS -->
            <span class="montserrat black"> {{this.classLocation.name}}</span>
          </div>

          <!-- PRICING COURSE -->
          <div class="pricing-course">
            <svg style="color: rgb(0, 0, 0);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16"> <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" fill="#000000"></path> </svg>            <span class="montserrat-semibold black"> Precio: </span>
            <span class="montserrat black">{{classCourse.classprice}}€</span>
          </div>
        </div>

        <!-- IMAGES & MAPS -->
        <div class="content-class-image">
          <div class="description-place">
            <div class="container-image-place">
              <!-- IMAGE PLACE -->
              <img
                v-bind:src="this.classLocation.picture" />
            </div>
          </div>

          <div class="container-map mt-3">
            <!-- IMAGE PLACE -->
            <img v-if="this.classLocation.longitud && this.classLocation.latitud" alt="Mapa" :src='`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+000(${this.classLocation.longitud},${this.classLocation.latitud})/` + this.classLocation.longitud + "," + this.classLocation.latitud + ",13,0/600x300?access_token=pk.eyJ1IjoiYmVuamFtaW5rYXJhdHNjaGluZXoiLCJhIjoiY2wzZWhvcm1oMGM5dDNibnJwZDdramxmbiJ9.YOOxcqslj-3uF8az7pKKyA"' />
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="description">
          <p class="montserrat-semibold black is-size-text-normal">
            Descripción</p>
          <p
            class="opensans gray-dark is-size-small-text mt-3">
            {{this.classLocation.description}}
          </p>
        </div>

        <span v-if="this.isAdmin" class="montserrat-semibold black">Participantes</span>
        <div v-if="this.selectedClass.bookings.length > 0 && this.isAdmin" id="detailclass-profile-container">
        <div
          v-for="(booking, idx) in this.selectedClass.bookings"
          :key="idx"
          class="profile_card_container-admin"
        >
          <!-- CARD STUDENT-->
          <div
            class="profile_card-admin"
            @click.prevent="goToProfile(booking.profile_id)"
          >
            <div class="container-profile-card is-flex is-justify-content-space-between mt-1">

              <div class="is-flex is-align-items-center user-student">
                <!-- PICTURE USER-->
                <img
                  class="is-rounded image-avatar-panel"
                  :src="$store.getters.getProfile(booking.profile_id).avatar"
                >
              
                <div class="ml-4 info-user-student">
                  <!-- NAME USER STUDENT-->
                  <p 
                    class="montserrat-bold black name-user">
                    {{$store.getters.getProfile(booking.profile_id).firstname}} {{$store.getters.getProfile(booking.profile_id).lastname}}
                  </p>
                </div>
              </div>

              <!-- BUTTON GO TO DETAIL PROFILE STUDENT-->
              <div class="go-profile">
                <div class="button button-go-to-profile">
                  <svg width="11" height="20" viewBox="0 0 11 20" fill="none">
                    <path d="M1 19L10 10L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
        <p v-else-if="this.isAdmin" class="opensans gray-dark is-size-small-text mt-3"> Aún no hay estudiantes que han reservado esta clase </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs'
let localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.locale('es')
Vue.use(dayjs);

export default {
  name: 'DetailClass',
  data() {
    return {
      selectedClass: null,
      classCourse: null,
      classLocation: null,
      isAdmin: false,
    }
  },
  created () {
      this.selectedClass = this.$store.getters.getClass(this.$store.state.selectedClass)
      this.classCourse = this.$store.getters.getCourse(this.selectedClass.course_id)
      this.classLocation = this.$store.getters.getLocation(this.selectedClass.location._id)
      this.isAdmin = this.$store.state.user.typeofuser == "Admin" || this.$store.state.user.typeofuser == "Mentor"
      if (window.location.pathname.split('/')[2] === 'calendario') {
        this.$store.commit('ChangecameFromCalendar', true)
      } else {
        this.$store.commit('ChangecameFromCalendar', false)
      }
  },

  filters: {
    toDay: (date) => {
      let weekDayString = dayjs(date).format('dddd, ')
      let dayString = dayjs(date).format('D.')
      let monthString = dayjs(date).format('MMMM')
      return weekDayString.charAt(0).toUpperCase() + weekDayString.slice(1) + dayString + " " + monthString.charAt(0).toUpperCase() + monthString.slice(1)
    }
  },

  methods: {
    goToProfile (id) {
      this.$store.commit('closeModal')
      this.$router.push(`/admin/estudiantes/${id}`)
    },
    formatHours(date, course_date) {
      // console.log(date)
      const now = new Date()
      const jan = new Date(now.getFullYear(), 0, 1).getTimezoneOffset();
      const jul = new Date(now.getFullYear(), 6, 1).getTimezoneOffset();
      const isDST =  Math.max(jan, jul) !== now.getTimezoneOffset();

      //Check if the course was not created in DST
      const d = new Date(course_date)
      const jan_course = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      const jul_course = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      const courseCreatedInDST =  Math.max(jan_course, jul_course) !== d.getTimezoneOffset();

      return isDST && !courseCreatedInDST ? dayjs(date).subtract(1, 'hour').format('hh:mm A') : dayjs(date).format('hh:mm A')
    }
  }
};
</script>

<style scoped>
.header-modal {
  background-color: #000;
  height: 72px;
  padding-top: 23px;
}

.container-header {
  width: 85%;
  margin: auto;
  justify-content: space-between;
  display: flex;
}


.date-class {
  background-color: #ede9e9;
  height: 80px;
  padding-top: 29px;
}


.container-date-class {
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
}

.square {
  width: 12px;
  height: 12px;
  background-color: #F29713;
  border-radius: 2px;
}

.info-class {
  width: 70%;
  margin: auto;
}

#detailclass-profile-container {
  padding-top: 30px;
  max-width: 800px;
}

.profile_card-admin {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 95%;
  margin: 0px 20px 10px 0px !important;
  padding: 5px 7px 5px 26px;
  min-height: 40px;
  border-radius: 2px;
}

.button {
  border: none !important;
  margin-left: 0px;
}

.user-student {
  width: 90%;
}

.image-avatar-panel {
  width: 30px;
  height: 30px;
}

.info-user-student {
  width: 80%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.container-profile-card {
  min-width: 90%;
}

.container-info-class {
  max-width: 481px;
}

.name-user {
  font-size: 15px;
}

.go-profile {
  border-left: 1px solid #ECECEC;
  width: 10%;
}

.content-class {
  display: flex;
  justify-content: space-between;
  padding-bottom: 23px;
  margin-top: 37px;
}

.content-place {
  display: flex;
  justify-content: space-between;
  padding-bottom: 23px;
  margin-top: 37px;
}

.border {
  border-bottom: 1px solid #e4e4e4;
}

.content-class-image {
  padding-bottom: 23px;
  margin-top: 7px;
}

.container-image-place img {
  width: 100%;
  max-width: 500px;
  max-height: 200px;
  object-fit: cover;
}

.container-map {
  width: 100%;
  max-width: 600px;
  max-height: 300px;
  object-fit: contain;
}

.description {
  padding-bottom: 90px;
}

@media only screen and (max-width: 613px) {

  .content-class , .description {
    max-width: 415px;
  }

  .content-class span {
    font-size: 14.5px;
  }

}

@media only screen and (max-width: 486px) {

  .container-header {
    width: 90%;
  }

  .container-header p {
    font-size: 15px;
  }

  .button-close {
    width: 14px;
    height: 14px;
  }

  .container-date-class span {
    font-size: 14.5px;
  }

  .info-class {
    width: 82%;
  }

  .content-class {
    display: block;
  }

  .content-class span {
    font-size: 14px;
  }

  .place-course span {
    font-size: 14px;
  }

  .level-course {
    margin-top: 20px;
  }

  .content-place {
    padding-bottom: 14px;
    margin-top: 25px;
  }
  .container-date-class {
    width: 90%;
  }
}

@media only screen and (max-width: 865px) {
  #profiles_container-admin {
    width: 100%;
  }

  .type-user {
    margin-left: 0px;
  }

  .button {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

@media only screen and (max-width: 641px) {
  
  
  .profile_card-admin {
    width: 100%;
    padding-right: 20px;
  }

  .info-user-student p {
    font-size: 13px;
  }

  .go-profile {
    width: 7%;
    margin-left: 12px;
    padding-left: 0px;
  }
}
</style>