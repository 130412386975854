<template>
  <div id="confirmation">

    <div class="space-45"></div>
    <div class="container-confirmation">
      <span class="montserrat-bold black">Semanas eligidas</span>
      <!-- DATE CLASS -->
      <div class="date-class-reserved"
      v-for="(week, index) in $store.state.weeklyBookingState.weeks"
          :key="index">
        <span class="montserrat black"><b class="montserrat-extrabold">·</b> {{ week | toDay }}</span> <br>
      </div><br>

      <!-- NAME COURSE -->
      <span class="opensans black"> Curso:</span>
      <span class="OpenSans-SemiBold black ml-1">
        {{ $store.state.weeklyBookingState.course.name }}
      </span>

      <!-- NAME STUDENT -->
      <div class="student-container">
        <span class="opensans black"> Estudiante:</span>
        <span class="OpenSans-SemiBold black ml-1">
          {{
            $store.state.weeklyBookingState.student.firstname +
            " " +
            $store.state.weeklyBookingState.student.lastname
          }}</span
        >
      </div>

      <div class="info-complementary">
        <!-- <div>
          <span class="opensans black"> Nivel:</span>

          <span class="OpenSans-SemiBold black ml-1">
            {{ $store.state.weeklyBookingState.course.skilllevel }}</span
          >
        </div> -->

        <div class="pricing-container">
          <span class="opensans black"> Precio:</span>
          <!-- PRICING CLASS -->
          <span class="OpenSans-SemiBold black ml-1">
            {{ $store.state.weeklyBookingState.price + '€' }}
          </span>
        </div>
      </div>
      <b-message
        type="is-info"
        has-icon
        class="OpenSans light"
        style="margin-top: 8px"
      >
        Recuerda revisar el horario y el lugar antes de ir a clase, ya que puede cambiar dependiendo de las condiciones climáticas.
        <span style="font-style: italic">{{
          this.$store.state.weeklyBookingState.course.skillevel
        }}</span>
      </b-message>
    </div>
    <button class="button-primary-medium" v-on:click="newWeeklyBooking">
      Reservar
    </button>
  </div>
</template>

<script>
import BookingService from '@/services/BookingService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import dayjs from 'dayjs'

var localizedFormat = require('dayjs/plugin/localizedFormat')
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
var locale = require('dayjs/locale/es')
dayjs.locale('es')

export default {
  name: 'confirmation',
  async mounted () {

  },
  filters: {
    toDay: (week) => {
      let weekStart = week[0]
      let weekEnd = week[1]
      let weekString = weekStart.format('D [d]e MMMM') + " al " + weekEnd.format('D [d]e MMMM')
      return weekString
    }
  },
  methods: {
    confirmReservation() {
        this.$store.commit('incrementBookingStep')
        this.$store.commit('reservationSuccessfull')
    },

    calculatePrice(course, profile) {
      if (course.residentDiscount > 0 && profile.residente) return course.classprice*((100-course.residentDiscount)/100)
      if (course.federadoDiscount > 0 && profile.federado) return course.classprice*((100-course.federadoDiscount)/100)
      else return course.classprice
    },

    async newWeeklyBooking() {
      const newWeeklyBookingObj = {
        profile_id: this.$store.state.weeklyBookingState.student._id,
        classes: this.$store.state.weeklyBookingState.classes,
        price: this.$store.state.weeklyBookingState.price
      }

      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await BookingService.NewWeeklyBooking(idToken, newWeeklyBookingObj)
      // console.log('------> NewBooking', response)

      if (response.status === 200 && response.data.msg === 'No tienes saldo suficiente para reservar') {
        this.$store.commit('closeModalR')
        this.$store.commit('clearWeeklyBookingDetails')
        this.$store.commit('updateUserDB')
        this.$store.commit('openModal', 'addcredits')
      } else if (response.status === 200) {
        this.danger(response.data.msg)    
      } else if (response.status === 201) {
        this.success('Reserva realizada')
        this.$store.commit('closeModalR')
        this.$store.commit('clearWeeklyBookingDetails')
        this.$store.commit('updateUserDB')
      }
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

  }
}
</script>

<style scoped>
.title-step {
  font-size: 15px;
  margin-top: 50px;
}

.space-45 {
  height: 45px;
}

.container-confirmation {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 2px;
  max-width: 403px;
  padding: 22px 17px 22px 23px;
}

.date-class-reserved span {
  font-size: 15px;
}

.course-title {
  margin-top: 8px;
  font-size: 13px;
}

.student-container {
  margin-top: 8px;
}

.student-container span {
  font-size: 15px;
}

.info-complementary {
  font-size: 15px;
  border-top: 1px solid #ececec;
  margin-top: 13px;
  padding-top: 10px;
}

.pricing-container {
  margin-top: 8px;
}

.button-primary-medium {
  margin-top: 20px;
}
</style>