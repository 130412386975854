<template>
  <div id="panel-pagos">
    <div class="container-pagos">
      <div class="header-pagos">
        <p
          class="montserrat-bold black">TOTAL</p>
        <!-- TOTAl NUMBER -->
        <p
          class="montserrat-bold orange total-amount">{{total}} €</p>
      </div>


      <!-- LIST TRANSACTION --->
      <div
        class="list-transacciones">
        <div class="conatiner-transacciones">
          <span class="montserrat-bold orange small-text">TRANSACCIONES</span>

          <!-- TRANSACCION ENTRADA --->
          <div
            v-for="(payment, index) in payments"
            :key= index 
            class="content-transaction">

            <div>
              <!-- TYPE TRANSACTION --->
              <p
                class="montserrat-semibold black type-transaction">
                Recarga</p>



              <!-- NAME USER TRANSACTION --->
              <p
                class="gray-light opensans user-transaction">
                {{payment.user.firstname + ' ' + payment.user.lastname}}</p>
            </div>

            <!-- DATE TRANSACTION --->
            <div>
              <span
                class="OpenSans-medium black date-transaction">
                {{ payment.dateFecha  }}</span> <br>
              <span
              class="OpenSans-medium black date-transaction">
                 {{payment.dateHora}}
              </span>
            </div>

            <!-- AMOUNT TRANSACTION --->
            <div class="positve-transaccion">
              <svg class="ml-2" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M7 13L7 1" stroke="#00D6CB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 7L7 1L13 7" stroke="#00D6CB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>


              <p class="montserrat-semibold amount">
                {{payment.amount}}€</p>
            </div>


            <!-- TOTAL TRANSACTION --->
            <div>
              <p class="montserrat black small-text">
                PAGADO</p>

              <!-- TOTAL AMOUNT --->
              <p
                class="montserrat-bold black total">
                {{payment.total}}€</p>
            </div>

          </div>


          <!-- TRANSACCION SALIDA --->
          <!-- <div class="content-transaction">

            <div>

              <p
                class="montserrat-bold black type-transaction">
                Payin</p>


              <p
                class="description-transaction gray-medium opensans text-overflow">
                Clase Martes / Curso de verano Agosto 2022</p>


              <p
                class="gray-light opensans user-transaction">
                Joselito Carnaval</p>
            </div>


            <div>
              <span
                class="OpenSans-medium black date-transaction date-transaction">
                May 21</span>
            </div>

 
            <div class="positve-transaccion">
              <svg class="ml-2" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M7 1L7 13" stroke="#FE5E33" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13 7L7 13L1 7" stroke="#FE5E33" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>


              <p
                class="montserrat-semibold amount-negative">
                50€</p>
            </div>



            <div>
              <p class="montserrat black small-text">
                TOTAL</p>


              <p
                class="montserrat-bold black total">
                155€</p>
            </div>

          </div> -->
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import PaymentService from "../../services/PaymentService"
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import dayjs from 'dayjs'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
var locale = require('dayjs/locale/es')
dayjs.locale('es')


export default {
  name: 'AdminPagos',
  data () {
    return {
      payments: null,
      total: 0
    }
  },

  async created () {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const response = await PaymentService.GetPayments(idToken)
    const paymentsRaw = response.data

    let arr = []
    let parcialTotal = 0
    let counter = 0

    for (let i = 0; i < paymentsRaw.length; i++) {

      parcialTotal = parcialTotal + paymentsRaw[i].amount
      const obj = {
        user: paymentsRaw[i].user_id,
        amount: paymentsRaw[i].amount,
        dateFecha: dayjs(paymentsRaw[i].date).format('dddd LL'),
        dateHora: dayjs(paymentsRaw[i].date).format('h:mm A'),
        total: parcialTotal,
      }
      arr.push(obj)
      this.total = this.total + paymentsRaw[i].amount
      counter++
      // console.log(arr)

      if (counter >= paymentsRaw.length) {
        this.payments = arr
      }    
    }


  },  
}
</script>

<style scoped>

#panel-pagos {
  height: 100vh;
  color: #FAFAFA;
}

.container-pagos {
  padding-top: 114px;
  width: 70%;
  margin: auto;
}

.total-amount {
  font-size: 41px;
}

.list-transacciones {
  margin-top: 38px;
}

.small-text {
  font-size: 12px;
}

.content-transaction {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ECECEC;
}

.amount {
  color: #00D6CB;
}

.amount-negative {
  color: #FE5E33;
}

.description-transaction {
  width: 220px;
}

@media only screen and (max-width: 700px) {
  .container-pagos {
    width: 80%;
  }
}

@media only screen and (max-width: 595px) {
  .container-pagos {
    width: 85%;
  }

  .header-pagos {
    text-align: center;
  }

  .total-amount {
    font-size: 29px;
  }

  .small-text {
    font-size: 11px;
  }

  .type-transaction {
    font-size: 13px;
  }

  .description-transaction {
    font-size: 12.5px;
    width: 130px;
  }

  .user-transaction {
    font-size: 12.5px;
  }

  .date-transaction {
    font-size: 13px;
  }

  .amount-negative {
    font-size: 13px;
  }

  .amount {
    font-size: 13px;
  }

  .small-text {
    font-size: 10px;
  }

  .total {
    font-size: 13px;
    margin-top: 7px;
  }
}

@media only screen and (max-width: 450px) {
  .container-pagos {
    width: 90%;
  }
}
</style>