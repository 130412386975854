<template>
  <div id="new-class">

    <div class="header-modal">

      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold black">Nueva Clase</h3>
        
        <!-- BUTTON CLOSE -->
        <a @click="closeModal">
          <div>
            <svg class="button-close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 2L2 18" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 2L18 18" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
    </div>

    
    <!-- FORM EDIT CLASS -->
    <div class="form-create-class">
      
      <div class="container-form">
        <!-- NAME COURSE -->
        <h3 class="montserrat-bold black">
          Curso de Verano - Agosto 2022</h3>


        <!-- FORM NEW CLASE -->
        <form>
          <div class="date-students">

            <!-- DATE START -->
            <b-field label="Fecha de nueva clase">
              <b-datepicker
                v-model="classDate"
                :show-week-number="showWeekNumber"
                :locale="locale"
                placeholder="Seleccionar fecha"
                icon="calendar-today"
                :icon-right="selected ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDate"
                trap-focus>
              </b-datepicker>
            </b-field>

          </div>

          <div class="mentor-place mt-5">

            <b-field class="start" label="Comienzo">
              <b-select
                v-model="startClassHour"
                placeholder="Elige una hora">
                  <option
                    v-for="(hour, index) in hoursOfDayStart"
                    :key="index" :value="hour">{{hour}}
                  </option>
              </b-select>
            </b-field>

            <b-field class="end ml-3" label="Final">
              <b-select
                v-model="endClassHour"
                placeholder="Elige una hora">
                  <option
                    v-for="(hour, index) in hoursOfDayEnd"
                    :key="index" :value="hour">{{hour}}</option>
              </b-select>
            </b-field>
            <b-field class="end ml-3" label="# Estudiantes permitido">
                <b-input
                  type="number"
                  v-model="maxStudents"
                  placeholder="24">
                </b-input>
              </b-field>

          </div>
          <div class="mentor-place">
  
                <!-- MENTOR -->
                <b-field class="mentor" label="Mentor">
                  <b-select 
                    v-model="profesorSelected" 
                    placeholder="Seleccionar">
                    <option
                      
                      v-for="(profesor, idx) in profesores"
                      :value="profesor._id"
                      :key="idx"> {{ profesor.firstname + ' ' + profesor.lastname }}
                    </option>
                  </b-select>
                </b-field>

                <!-- PLACE -->
                <b-field class="place ml-2" label="Lugar de clase">
                  <b-select 
                    v-model="locationSelected" 
                    placeholder="Seleccionar">
                    <option
                      
                      v-for="(location, idx) in locations"
                      :value="location._id"
                      :key="idx"> {{ location.name}}
                    </option>
                  </b-select>
                </b-field>
              </div>

        </form>
      </div>
    </div>

    <div class="container-buttons">
      <button @click="closeModal" class="button-medium-stroke-black">Cancelar</button>
      <button
        @click="newClass"
        class="button-primary-extra-medium ml-2">Guardar</button>
    </div>

  </div>


</template>

<script>

import ClassService from '@/services/ClassService'
import CourseService from '@/services/CourseService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'NewClass',
  components: {
  },
  computed:  {
    profesores: function () {
      return this.allProfiles.filter(profile => profile.isMentor && profile.status === 'active')
    }
  },
  watch: {
      startClassHour: function () {
        let index = this.hoursOfDay.indexOf(this.startClassHour) // if I choose '01:00' --> 2
        this.hoursOfDayEnd = this.hoursOfDay.slice(index+1)
      },
      endClassHour: function () {
        let index = this.hoursOfDay.indexOf(this.endClassHour) // if I choose '23:00' --> 47
        this.hoursOfDayStart = this.hoursOfDay.slice(0, index)
        
      },
  },

  data () {
    return {
      startClassHour: null,
      endClassHour: null,
      maxStudents: null,
      classDate: new Date(),
      bindProps: {
        mode: 'international',
        preferredCountries: ['ES'],
        onlyCountries: [],
        ignoredCountries: []
      },
      profesorSelected: null,
      locationSelected: null,
      allProfiles: this.$store.state.allProfiles,
      locations: this.$store.state.locations,
      hoursOfDay: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'],
      hoursOfDayStart: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'],
      hoursOfDayEnd: null
    }
  },

  // METHODS
  methods: {

    // Close modal
    closeModal () {
      this.$store.commit('closeModal')
    },

    // NewClass
    async newClass () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const newClassObj = {
        course_id: this.$store.getters.currentCourse._id,
        classDate: this.classDate,
        startClassHour: this.startClassHour,
        endClassHour: this.endClassHour,
        location: this.locationSelected,
        mentor: this.profesorSelected,
        maxstudents: this.maxStudents,
        residentDiscount: this.$store.getters.currentCourse.residentDiscount,
        federadoDiscount: this.$store.getters.currentCourse.federadoDiscount,
      }

      const response = await ClassService.NewClass(idToken, newClassObj)

      if (response.status === 201) {
        this.$store.commit('updateCourseClasses', window.location.pathname.split('/')[3])
        await firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            await CourseService.GetCourses(idToken)
              .then(response => {
                if (response.status === 200) {
                  this.$store.commit('updateAllCourses', response.data)
                  this.closeModal()
                }
              })
              .catch(()=>{})
          })
      }

    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  }
}


</script>


<style scoped>

.header-modal {
  width: 100%;
  background-color: #EFEFEF;
  height: 72px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.form-create-class {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 50px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.container-form {
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.date-students {
  display: flex;
  margin-top: 40px;
}

.max-student {
  margin-left: 10px;
}

.duration-date {
  display: flex;
}

.hour-field {
  margin-left: 10px;
}

.mentor-place {
  display: flex;
}



.place {
  margin-left: 10px;
}

.container-buttons {
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 40px;
  
}

@media only screen and (max-width: 500px) {
  .container-header-modal {
    width: 90%;
  }

  .button-close {
    width: 15px;
    height: 15px;
  }

  .container-header-modal h3 {
    font-size: 15px;
  }

  

  .header-modal {
    height: 60px;
  }

  .form-create-class  {
    width: 91%;
  }

  .container-buttons {
    width: 91%;
    margin-top: 21px
  }

  .form-create-class {
    margin-top: 30px;
  }

  .date-students {
    display: block;
  }

  .max-student {
    margin-left: 0px;
  }

  .duration-date {
    margin-top: 15px;
  }

  .mentor-place {
    margin-top: 3px;
  }

  .place {
    margin-left: 0px;
  }

  .level {
    margin-top: 15px;
  }

  .container-buttons {
    margin-bottom: 32px;
  }

}

</style>
