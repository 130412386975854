<template>
  <div id="class">
    <div class="container-class">
      <div class="top">
        <!-- PENDING CLASSES -->
        <div
          v-if="$store.getters.profileClassesFormated"
          class="pending-classes"
        >
          <!-- NUMBER PENDING CLASSES -->
          <p
            v-if="$store.getters.profileClassesFormated"
            class="montserrat-bold number-classes"
          >
            {{ $store.getters.profileClassesFormated.length }}
          </p>

          <div class="text-pending">
            <p class="montserrat-bold black">
              {{
                $store.getters.profileClassesFormated.length > 1
                  ? "Clases"
                  : "Clase"
              }}
            </p>
            <p class="montserrat-bold black">
              {{
                $store.getters.profileClassesFormated.length > 1
                  ? "Pendientes"
                  : "Pendiente"
              }}
            </p>
          </div>
        </div>

        <!-- CLASSES HISTORY -->
        <!-- <b-field class="select-status-classes">
          <b-select  placeholder="Seleccionar">
            <option
              v-for="option in data"
              :value="option.id"
              :key="option.id">
                Pendientes
            </option>
            <option
              v-for="option in data"
              :value="option.id"
              :key="option.id">
                Histórico
            </option>
          </b-select>
        </b-field> -->
      </div>

      <!-- CLASSES LIST -->
      <div class="container-classes">
        <!-- CARD CLASS-->
        <div
          v-for="(clase, index) in $store.getters.profileClassesFormated"
          :key="index"
          class="classes-content"
        >
          <div class="info-classes-principal">
            <!-- DATE & HOUR CLASS -->
            <div class="schedule-class">
              <!-- DATE-->
              <span class="montserrat-semibold black is-size-text-normal">
                {{ clase.startclass + "," }}</span
              >

              <!-- HOUR -->
              <span class="montserrat text-tranformation-uppercase">
                {{ formatHours(clase.start, clase.course_id) + " " }} -
                {{ formatHours(clase.end, clase.course_id) + " " }}
              </span>
            </div>

            <!-- CLASS  LEVEL -->
            <!-- <p
              class="opensans nivel mt-2 is-size-text-normal">
              {{clase.skilllevel}}</p> -->

            <!-- CLASS MENTOR -->
            <div class="info-complementary-class is-flex mt-2">
              <div class="is-flex is-size-text-normal">
                <span class="OpenSans-SemiBold black">Mentor:</span>

                <!-- NAME MENTOR -->
                <span class="opensans ml-1"> {{ clase.mentor }}</span>
              </div>
              <div class="is-flex place-left is-size-text-normal">
                <span class="OpenSans-SemiBold black"> Lugar:</span>

                <!-- PLACE -->
                <span class="opensans ml-1">{{ clase.location }}</span>
              </div>
            </div>
          </div>

          <!-- BUTTONS CLASSES  -->
          <div class="buttons-classes">
            <button
              @click="openDetailClass(clase._id)"
              class="button-medium-stroke"
            >
              Ver más
            </button>
            <br />
            <button
              @click="cancelClass(clase._id)"
              class="button-medium-stroke button-cancel"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BookingService from "@/services/BookingService";
  import firebase from "firebase/compat/app";
  import "firebase/compat/auth";
  import ProfileService from "@/services/ProfileService";
  import ClassService from "@/services/ClassService";
  import dayjs from "dayjs";
  let localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);
  dayjs.locale("es");

  export default {
    name: "Perfil",
    components: {},
    // data () {
    //   return {
    //   }
    // },

    // CREATED
    created() {
      this.$store.commit(
        "updateProfileClasses",
        window.location.pathname.split("/")[3]
      );
    },

    mounted() {
      this.$store.commit(
        "updateCurrentProfile",
        window.location.pathname.split("/")[3]
      );
    },

    methods: {
      async cancelClass(classId) {
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        const studentId = window.location.pathname.split("/")[3];

        const clase = this.$store.getters.getClass(classId);
        const course = this.$store.getters.getCourse(clase.course_id);
        if (course.coursetype !== "campamento") {
          const response = await BookingService.DeleteBooking(
            idToken,
            classId,
            studentId
          );
          if (response.status == 200) {
            await ProfileService.getAllProfiles(idToken).then((profiles) => {
              this.$store.commit("updateAllProfiles", profiles.data);
            });
            this.$store.commit("updateUserDB");
            this.$store.commit(
              "updateProfileClasses",
              window.location.pathname.split("/")[3]
            );
            this.success(response.data.msg);
          } else if (response.status == 203) {
            this.danger(response.data.msg);
          }
        } else
          this.danger(
            "Lo sentimos, no puedes cancelar una clase de campamento"
          );
      },

      openDetailClass(id) {
        ClassService.GetClass().then((response) => {
          if (response.status === 200) {
            this.$store.commit("updateAllClasses", response.data);
            this.$store.commit("selectedClass", id);
            this.$store.commit("openModal", "detailclass");
          }
        });
      },
      // Danger Toast
      danger(msg) {
        this.$buefy.toast.open({
          duration: 5000,
          message: msg,
          position: "is-bottom",
          type: "is-primary",
        });
      },

      // Success Toast
      success(msg) {
        this.$buefy.toast.open({
          duration: 4000,
          message: msg,
          position: "is-top",
          type: "is-success",
        });
      },

      formatHours(date, course_id) {
        // console.log(date)
        const course_date =
          this.$store.getters.getCourse(course_id).startcourse;

        const now = new Date();
        const jan = new Date(now.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(now.getFullYear(), 6, 1).getTimezoneOffset();
        const isDST = Math.max(jan, jul) !== now.getTimezoneOffset();

        //Check if the course was not created in DST
        const d = new Date(course_date);
        const jan_course = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        const jul_course = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        const courseCreatedInDST =
          Math.max(jan_course, jul_course) !== d.getTimezoneOffset();

        console.log("Course created in DST: ", courseCreatedInDST);
        console.log("User is in DST: ", isDST);
        console.log("Date: ", date);

        return isDST && !courseCreatedInDST
          ? dayjs(date).subtract(1, "hour").format("hh:mm A")
          : dayjs(date).format("hh:mm A");
      },
    },
  };
</script>

<style scoped>
  #class {
    background-color: #fafafa;
    height: 100vh;
  }

  .container-class {
    background-color: #fff;
    border: 1px solid #ededed;
    width: 70%;
    height: 100vh;
    max-width: 882px;
    margin: auto;
    margin-left: 19%;
    margin-top: -59px;
    position: absolute;
    z-index: 1;
    border-radius: 0px;
    padding-bottom: 40px;
  }

  .top {
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .pending-classes {
    display: flex;
    align-items: center;
  }

  .number-classes {
    font-size: 48px;
    color: #000;
  }

  .text-pending p {
    line-height: 16px;
    margin-left: 12px;
  }

  .classes-content {
    display: flex;
    background-color: #f5f3f3;
    width: 90%;
    margin: auto;
    margin-top: 15px;
    justify-content: space-between;
    padding: 13px 27px 12px 39px;
  }

  .place-left {
    margin-left: 12px;
  }

  .button-cancel {
    margin-top: 15px;
  }

  @media only screen and (max-width: 903px) {
    .classes-content {
      padding: 13px 6px 12px 11px;
      display: block;
    }

    .info-complementary-class {
      flex-direction: column;
    }

    .place-left {
      margin-left: 0px;
      margin-top: 9px;
    }

    .buttons-classes {
      display: flex;
      margin-top: 15px;
      align-items: center;
    }

    .button-cancel {
      margin-top: 0px;
      margin-left: 9px;
    }
  }

  @media only screen and (max-width: 723px) {
    .container-class {
      width: 95%;
      margin-left: 3%;
    }

    .number-classes {
      font-size: 37px;
    }
  }

  @media only screen and (max-width: 450px) {
    .top {
      display: block;
    }

    .container-classes {
      margin-top: 30px;
    }

    .select-status-classes {
      width: 90% !important;
      min-width: 283px !important;
      max-width: 430px;
      margin-top: 20px;
    }
  }
</style>
