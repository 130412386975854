<template>
  <div id="home">
    <div :class="top ? 'nvBar' : 'nvBar2'">

      <div class="container-nav-header">
        <div class="container-logo">           
          <img n class="logo" src="@/assets/images/navBar/logo_kontraola_white.svg" />
        </div>
        <div class="buttons-actionables">
          <div
            class="button-mobile-signup"
            v-if="!this.$store.state.authUser"
            @click.prevent="loginModal"
          >
            <svg width="21" height="18" viewBox="0 0 21 18" fill="none">
              <circle
                cx="10.1739"
                cy="4.69565"
                r="3.96894"
                stroke="white"
                stroke-width="1.45342"
              />
              <path
                d="M2.25296 15.8673C5.97841 10.1358 14.3694 10.1358 18.0949 15.8673L19.0087 17.2732H1.33909L2.25296 15.8673Z"
                stroke="white"
                stroke-width="1.45342"
              />
            </svg>
          </div>
          <button
            class="button-secundary-stroke mr-3 button-desk-signup"
            v-if="!this.$store.state.authUser"
            @click.prevent="loginModal"
          >
            Ingresar
          </button>
          <button
            class="button-secundary-stroke"
            v-if="!this.$store.state.authUser"
            @click.prevent="signupModal"
          >
            Registrarme
          </button>
          <button
            class="button-primary-black-small"
            v-if="this.$store.state.authUser"
            @click.prevent="logout"
          >
            Salir
          </button>
        </div>
      </div>
    </div>

    <div id="header_home">
      <!-- Splash -->
      <div style="display: flex">
        <!-- content video and filter -->



        <video
          :src="'https://player.vimeo.com/progressive_redirect/playback/716577728/rendition/720p/file.mp4?loc=external&signature=286dc5d20666c1e35dc37cfe25dd98a1fb12721b31d234c73648046a91817655'"
          ref="bgvid"
          id="bgvid"
          playsinline
          muted
          autoplay
          loop
          class="myVideo"
        ></video>

        <div class="content-principal">
          <div class="tag-line">
            <h2 class="title-medium-big montserrat-extrabold white ">
              Vive la experiencia aloha con seguridad y confianza.
            </h2>
            <p class="subtitle-big-medium montserrat-medium white mt-2">
              Aprende surf de mano de los mejores mentores
            </p>
          </div>

          <div class="container-buttons-home">
            <button
              class="button-primary-extra-big"
              @click.prevent="verCursos"
            >
              Ver Cursos
            </button>

            <br />
            <button
              class="button-white-extra-big mt-2"
              @click.prevent="signupModal"
            >
              Apuntarme
            </button>
            
          </div>
        </div>

        <!-- ver max -->
        <div class="button-see-more">
          <div class="container-button-see-more">
            <p class="white mb-2 opensans">ver más</p>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none">
              <path
                d="M1 1L7 7L13 1"
                stroke="white"
                stroke-opacity="0.93"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <!-- <div class="content-principal">
        <div class="tag-line">
          <h2 
            class="
            title-medium-big
            montserrat-extrabold 
            white
            mt-6">
            Vive la experiencia aloha con seguridad y confianza.
          </h2>
          <p
           class="
            subtitle-big-medium
            montserrat-medium
            white

            ">
            Aprende surf de mano de los mejores mentores</p>
        </div>
        <div class="no-mobile">
          <Booking/>
        </div>
      </div>

      <div class="no-desktop container-button-booking">
        <div class="button-booking-mobile">
          <BookingModal/>
        </div>
      </div> -->
    </div>

    <!-- Campamentos -->
    <div id="cursos" class="cursos">
      <div class="content-course">
        <span class="white montserrat-extrabold title-course">Campamentos</span>
        <span class="orange m-left montserrat-extrabold title-course"
          >activos</span>

        <div
          v-for="(curso, index) in $store.getters.allCoursesFormated"
          :key="index"
          class="cursosContainer">
          <div
            v-if="curso.active && curso.coursetype == 'campamento'"
            class="cursoBox"
          >
            <div  class="content-box-course">
              <div class="image-place-course">
                <img :src="curso.location.picture" />
              </div>

              <div class="center">
                <span class="montserrat-bold is-size-medium-text">{{
                  curso.name
                }}</span>

                <div>
                  <span class="montserrat-bold is-size-text-normal"
                    >Mentor:
                  </span>
                  <span class="montserrat is-size-text-normal">
                    {{ curso.mentor }}</span
                  >
                  <span class="montserrat-bold is-size-text-normal ml-3"
                    >Lugar:</span
                  >
                  <span class="montserrat is-size-text-normal">
                    {{ curso.location.name }}</span
                  >
                </div>

                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Horario:</p>
                  <span
                    class="montserrat mr-2 mobile-text"
                    v-for="(dia, index2) in curso.horario"
                    :key="index2"
                    > <b class="montserrat-extrabold">·</b> {{ dia }}
                  </span>
                </div>
                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Condiciones</p>
                  <span class="montserrat mr-2 mobile-text"> 
                    <p>{{ curso.conditions }} </p> 
                  </span>
                </div>
              </div>

              <div>
                <button
                class="button-primary-orange-small mleft-button"
                @click.prevent="signupModal"
              >
                Apuntarme
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
          <!-- Cursos -->
    <div id="cursos" class="cursos">
      <div class="content-course">
        <span class="white montserrat-extrabold title-course">Cursos</span>
        <span class="orange m-left montserrat-extrabold title-course"
          >activos</span>
      <div v-for="(curso, index) in $store.getters.allCoursesFormated" :key="index" class="cursosContainer">
          <div
            v-if="curso.active && curso.coursetype == 'groupcourse'"
            class="cursoBox"
          >
            <div  class="content-box-course">
              <div class="image-place-course">
                <img :src="curso.location.picture" />
              </div>

              <div class="center">
                <span class="montserrat-bold is-size-medium-text">{{
                  curso.name
                }}</span>

                <div>
                  <span class="montserrat-bold is-size-text-normal"
                    >Mentor:
                  </span>
                  <span class="montserrat is-size-text-normal">
                    {{ curso.mentor }}</span
                  >
                  <span class="montserrat-bold is-size-text-normal ml-3"
                    >Lugar:</span
                  >
                  <span class="montserrat is-size-text-normal">
                    {{ curso.location.name }}</span
                  >
                </div>

                <div class="mt-4">
                  <p class="montserrat-bold is-size-text-normal">Horario:</p>
                  <span
                    class="montserrat mobile-text"
                    v-for="(dia, index2) in curso.horario"
                    :key="index2"
                    > <b class="montserrat-extrabold">·</b> {{ dia }}
                  </span>
                </div>
              </div>

              <button
                class="button-primary-orange-small mleft-button"
                @click.prevent="signupModal"
              >
                Apuntarme
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    

    <div class="about-us">
      <div class="container-about-us">
        <div class="container-img-about-us">
          <img src="@/assets/images/home/image-about-us.png" />
        </div>
        <div class="content-about-us">
          <span class="black title-big montserrat-extrabold">Sobre</span>
          <br />
          <span class="black title-big montserrat-extrabold">Kontra</span>
          <span class="orange title-big m-left montserrat-extrabold">ola</span>

          <p class="paragraph-content gray-dark">
            Desde 1981 adoptamos la misión de compartir el amor por el surf, generando en nuestros alumnos la seguridad y confianza con  los mejores métodos de aprendizaje. Nos gustan las cosas prácticas y sencillas, así que puedes estar tranquil@ de que con nuestra guía, podrás vivir una experiencia auténtica y sobre todo vivirás ¡La experiencia Aloha! y llevarás tu surfing al punto más alto.
          </p>
        </div>
      </div>
    </div>

    <div class="purpose">
      <div class="container-purpose">
        <img src="@/assets/images/home/felix_surf.jpg" />
        <div class="text-purpose">
          <span class="white montserrat-bold">Un estilo</span>
          <span class="orange montserrat-bold margin-letf-purpuse"
            >de vida</span
          >
        </div>
      </div>
    </div>

    <div class="close-purpose">
      <div class="container-close-purpose">
        <div class="no-desktop">
          <h3 class="black montserrat-bold mobile-title">Que supera</h3>
          <h3 class="orange montserrat-bold mobile-title">barreras</h3>
        </div>

        <div class="container-image-purpose">
          <img src="@/assets/images/home/img-felix-mentor.webp" />
          <!--
          <img src="@/assets/images/home/img_felix_mentor.png" />-->
        </div>

        <div class="content-purpose">
          <h3 class="black montserrat-bold no-mobile">Que supera</h3>
          <h3 class="orange montserrat-bold no-mobile">barreras</h3>

          <p class="paragraph-content gray-dark">
            Llevamos nuestra filosofía de surf no solo a locales y extranjeros, también brindamos surf inclusivo. Cada uno de nosotros trabaja para que sea cual sea tu inconveniente, te puedas sentir todo un SURFER, y que la experiencia sea la misma para todos porque “Subirse en una ola y  poder Cabalgarla” es una sensación única e irrepetible que todos podemos vivir. Nunca es tarde para una primera vez. 
          </p>
        </div>
      </div>
    </div>

    <div class="benefits">
      <div class="container-benefits">
        <div class="container-title">
          <h3 class="white montserrat-extrabold">Nuestros</h3>
          <h3 class="orange montserrat-extrabold">Beneficios</h3>
        </div>
        <div class="benefit mtop-benefit pb-6">
          <div
            class="
              container-benefit
              is-flex is-align-items-end is-justify-content-space-between
            "
          >
            <div class="content-benefit">
              <img src="@/assets/images/home/surf_kontraola_tecnico.svg" />
              <h4 class="white montserrat-bold title-benefit">
                Única escuela que puede tecnificar.
              </h4>
              <p class="white paragraph-content">
                Podrás aprender de forma presencial y online, según lo que estés buscando. Estamos más que capacitados para lograr que alcances tus exigencias.
              </p>
            </div>
            <div class="container-image-benefit no-mobile right-align">
              <img src="@/assets/images/home/mentores_especializados.jpg" />
            </div>
          </div>
        </div>

        <div class="benefit pb-6 mt-6">
          <div
            class="
              container-benefit
              is-flex
              is-align-items-align-center
              is-justify-content-space-between
            "
          >
            <div class="container-image-benefit-left no-mobile">
              <img src="@/assets/images/home/surf-local-tenerife.png" />
            </div>

            <div class="content-benefit right-align">
              <img src="@/assets/images/home/surf-local.svg" />
              <h4 class="white montserrat-bold title-benefit">
                ¡Somos locales! Conocemos todos los rincones de la playa
              </h4>
              <p class="white paragraph-content">
                Son 39 años dedicándonos a compartir el deporte del surf a quienes nos visitan, aquí hemos crecido y conocemos este entorno como la palma de nuestra mano.
              </p>
            </div>
          </div>
        </div>

        <div class="benefit pb-6 mt-6">
          <div
            class="
              container-benefit
              is-flex is-justify-content-space-between is-align-items-end
            "
          >
            <div class="content-benefit">
              <img src="@/assets/images/home/Experiencia_aloha_surf.svg" />
              <h4 class="white montserrat-bold title-benefit-large">
                Experiencia surf positiva y que aporta a tu vida
              </h4>
              <p class="white paragraph-content">
                ¡Imposible es aquello que nunca se intenta! Vive con nosotros el espíritu aloha y llévate una habilidad que seguramente podrás compartir por generaciones.
              </p>
            </div>
            <div class="container-image-benefit img-vertical no-mobile">
              <img src="@/assets/images/home/experiencia_surf_positivo.png" />
              <img
                class="mt-6"
                src="@/assets/images/home/surf_playa_tenerife.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="faq">
      <div class="container-faq">
        <div class="title-faq">
          <h3 class="black montserrat-bold">Preguntas frecuentes</h3>
          <svg
            class="ola"
            width="83"
            height="16"
            viewBox="0 0 83 16"
            fill="none"
          >
            <path
              d="M1.02743 3.54864L7.31762 12.7424C8.89038 15.0412 12.2724 15.0689 13.8827 12.7963L19.6271 4.68884C21.3483 2.25957 25.0253 2.49491 26.4228 5.12379L29.7094 11.3064C31.1436 14.0043 34.9507 14.1632 36.6047 11.5941L41.5311 3.94184C43.0137 1.63893 46.32 1.47361 48.0249 3.61715L55.3176 12.786C57.0225 14.9295 60.3288 14.7642 61.8114 12.4613L67.4732 3.66673C68.9376 1.39206 72.1907 1.1968 73.9166 3.27998L81.1412 11.9997"
              stroke="#F29713"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>

        <div class="content-faq">
          <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
            :aria-id="'contentIdForA11y5-' + index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">
                  {{ collapse.title }}
                </p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                {{ collapse.text }}
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div> -->


    <div class="footer">
      <div class="container-footer">
        <div>
          <span class="montserrat gray-dark is-size-text-normal">Santa Cruz de Tenerife, España <b>Kontraola 2022</b></span></div>

        <div class="redes-sociales m-top-redes">
          <!-- FACEBOOK -->
          <a target="_blank" href="https://www.facebook.com/kontraolasurf/">
          <svg class="mr-4" width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0H6.54545C5.46048 0 4.41994 0.395088 3.65274 1.09835C2.88555 1.80161 2.45455 2.75544 2.45455 3.75V6H0V9H2.45455V15H5.72727V9H8.18182L9 6H5.72727V3.75C5.72727 3.55109 5.81347 3.36032 5.96691 3.21967C6.12035 3.07902 6.32846 3 6.54545 3H9V0Z" fill="#373737"/>
          </svg></a>


          <!-- INSTAGRAM -->
          <a target="_blank" href="https://www.instagram.com/kontraola_surf_tenerife/">
          <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0ZM8.38681 5.59139C7.88746 5.51735 7.37748 5.60264 6.9294 5.83514C6.48132 6.06764 6.11796 6.4355 5.89101 6.88642C5.66405 7.33733 5.58505 7.84832 5.66525 8.34672C5.74545 8.84512 5.98076 9.30554 6.33771 9.66249C6.69467 10.0194 7.15508 10.2548 7.65348 10.335C8.15188 10.4152 8.66287 10.3362 9.11378 10.1092C9.5647 9.88224 9.93257 9.51888 10.1651 9.0708C10.3976 8.62272 10.4829 8.11274 10.4088 7.61339C10.3333 7.10404 10.0959 6.63248 9.73183 6.26838C9.36772 5.90427 8.89616 5.66692 8.38681 5.59139ZM6.19249 4.41494C6.93929 4.02744 7.78926 3.88529 8.6215 4.0087C9.47043 4.13458 10.2564 4.53016 10.8632 5.13701C11.47 5.74385 11.8656 6.52978 11.9915 7.3787C12.1149 8.21095 11.9728 9.06091 11.5853 9.80771C11.1978 10.5545 10.5847 11.1601 9.83313 11.5384C9.08161 11.9166 8.22995 12.0483 7.39929 11.9146C6.56863 11.781 5.80126 11.3888 5.20634 10.7939C4.61142 10.1989 4.21923 9.43157 4.08557 8.60091C3.95191 7.77025 4.08357 6.91859 4.46183 6.16707C4.84009 5.41555 5.44569 4.80244 6.19249 4.41494ZM12.4 2.8C11.9582 2.8 11.6 3.15817 11.6 3.6C11.6 4.04183 11.9582 4.4 12.4 4.4H12.408C12.8498 4.4 13.208 4.04183 13.208 3.6C13.208 3.15817 12.8498 2.8 12.408 2.8H12.4Z" fill="#373737"/>
          </svg></a>


          <!-- YOUTUBE -->
          <a href="https://www.youtube.com/channel/UCyO0AogWy4Wx9W8a2Yt3jRg" target="_blank">
          <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8392 1.15941C21.1799 1.51057 21.4218 1.94541 21.5406 2.42C21.8578 4.1787 22.0118 5.96295 22.0006 7.75C22.0069 9.51033 21.8529 11.2676 21.5406 13C21.4218 13.4746 21.1799 13.9094 20.8392 14.2606C20.4986 14.6118 20.0713 14.8668 19.6006 15C17.8806 15.46 11.0006 15.46 11.0006 15.46C11.0006 15.46 4.12059 15.46 2.40059 15C1.93941 14.8738 1.51857 14.6308 1.17873 14.2945C0.838896 13.9581 0.591548 13.5398 0.460589 13.08C0.143357 11.3213 -0.0106316 9.53704 0.000589127 7.75C-0.00817495 5.97631 0.145804 4.20556 0.460589 2.46C0.579378 1.98541 0.821305 1.55057 1.16194 1.19941C1.50257 0.84824 1.92984 0.593185 2.40059 0.46C4.12059 0 11.0006 0 11.0006 0C11.0006 0 17.8806 0 19.6006 0.42C20.0713 0.553185 20.4986 0.808241 20.8392 1.15941ZM14.5006 7.75047L8.75057 11.0205V4.48047L14.5006 7.75047Z" fill="#373737"/>
          </svg></a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import Booking from '@/components/home/booking.vue'
// import BookingModal from '@/components/home/bookingModal.vue'

export default {
  name: "Home",
  components: {},

  //DATA
  data() {
    return {
      top: true,
      isOpen: 0,
      collapses: [
        {
          title: "Cómo puedo agendar el servicio de clases?",
          text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia",
        },
        {
          title: "Title 2",
          text: "Text 2",
        },
        {
          title: "Title 3",
          text: "Text 3",
        },
        {
          title: "Title 4",
          text: "Text 4",
        },
        {
          title: "Title 5",
          text: "Text 5",
        },
      ],
    };
  },

  mounted () {
    window.addEventListener('scroll', this.scrollpx)
  },

  // METHODS
  methods: {

    scrollpx() {
      if (window.scrollY > 0) {
        this.top = false
      } else {
        this.top = true
      }

    },

    verCursos() {
      document.getElementById("cursos").scrollIntoView();
    },
    loginModal() {
      this.$store.commit("openModal", "login");
    },
    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },
    signupModal() {
      // If the user is already logged in, don't open the signup modal, else open the signup modal
      if (firebase.auth().currentUser) {
        this.danger("Ya estás logueado");
        this.$router.push("/panel/cursos-activos");
        return
      } else {
        this.$store.commit("openModal", "signup");
      }
    },
    // Logout
    async logout() {
      // console.log('oio')
      await firebase.auth().signOut();
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>

<style scoped>
.container-logo {
  margin-top: 10px;
}

.nvBar {
  background-color: #ffffff00;
  width: 100%;
  height: 100px;
  position: fixed;
}

.nvBar2 {
  background-color: #000000c9;
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 2;

}

.myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.7);
}

#header_home {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  /* background-image: url("/img/home/surf_in_tenerife.jpg");   */
  background-size: cover;
}

.container-nav-header {
  width: 90%;
  margin: auto;
  margin-left: 5%;
  padding-top: 28px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
}

.content-principal {
  width: 60%;
  height: 58vh;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  z-index: 1;
}

.tag-line {
  width: 70%;
  max-width: 609px;
  margin: auto;
  margin-top: 45%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tag-line h2 {
  width: 90%;
  max-width: 450px;
}

.button-see-more {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.container-button-see-more {
  width: 20%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-button-see-more p {
  opacity: 0.7;
}

.title-big {
  font-size: 60px;
  line-height: 67px;
}

.title-medium-big {
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}

.subtitle-big-medium {
  font-size: 20px;
  margin-top: 48px;
  text-align: center;
}

.container-buttons-home {
  width: 40%;
  margin-top: 30px;
  max-width: 609px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* about us*/

.container-about-us {
  width: 70%;
  max-width: 954px;
  display: flex;
  margin: auto;
}

.container-img-about-us {
  width: 100%;
  max-width: 338px;
  margin-top: 121px;
}

.container-img-about-us img {
  width: 100%;
}

.content-about-us {
  width: 69%;
  margin-top: 140px;
  margin-left: 48px;
}

.content-about-us p {
  max-width: 478px;
  margin: 93px auto auto 33px;
}

/* COURSE */

.mobile-text {
  font-size: 13px;
  opacity: 0.6;
}

.content-course {
  padding-top: 150px;
  width: 80%;
  margin: auto;
}

.cursosContainer {
  margin-top: 25px;
}

.title-course {
  font-size: 30px;
}

.cursos {
  width: 100%;
  background-color: #171616;
  padding-bottom: 100px;
}

.cursoBox {
  width: 100%;
  background-color: rgb(140, 140, 140, 0.19);
  color: #fafafa;
  margin-top: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.content-box-course {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.image-place-course {
  object-fit: cover;
}

.image-place-course img {
  min-width: 120px;
  max-width: 130px;
  border-radius: 2px;
}

.center {
  max-width: 63%;
  min-width: 60%;
  margin-left: 17px;
  margin-right: 17px;
}

.m-left {
  margin-left: 10px;
}

/* Purpose kontraola*/

.container-purpose {
  height: 439px;
  width: 80%;
  margin: auto;
  margin-top: 196px;
  /*
  background-repeat: no-repeat;
  background-image: url("/img/home/image_felix.png");  
  background-size: cover;
  background-position-y: 60px;*/
}

.container-purpose img {
  width: 100%;
  height: 439px;
  object-fit: cover;
}

.text-purpose {
  margin-top: -210px;
  margin-left: 60px;
}

.text-purpose span {
  font-size: 48px;
}

.margin-letf-purpuse {
  margin-left: 12px;
}

/* purpose 2 kontraola*/

.container-close-purpose {
  width: 60%;
  max-width: 954px;
  display: flex;
  margin: auto;
}

.container-image-purpose {
  width: 95%;
  max-width: 456px;
  margin-top: 121px;
}

.content-purpose {
  width: 100%;
  margin-top: 170px;
}
.content-purpose h3 {
  font-size: 45px;
}

.content-purpose p {
  margin-top: 60px;
  max-width: 490px;
}

.no-desktop {
  display: none;
}

/* Benefits */

.benefits {
  background-color: #171616;
  width: 100%;
  margin-top: 150px;
  padding-bottom: 120px;
}

.mtop-benefit {
  margin-top: 107px;
}

.container-benefits {
  width: 65%;
  margin: auto;
}

.container-title {
  padding-top: 150px;
}

.container-title h3 {
  font-size: 60px;
}

.content-benefit {
  min-width: 340px;
  width: 30%;
}

.content-benefit img {
  max-width: 118px;
}

.content-benefit h4 {
  font-size: 26px;
  margin-top: 50px;
  margin-bottom: 55px;
}

.title-benefit {
  max-width: 307px;
}

.container-image-benefit img {
  width: 88%;
  max-width: 550px;
}

.container-image-benefit-left img {
  max-width: 376px;
}

.right-align {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-align h4 {
  text-align: right;
}

.right-align p {
  text-align: right;
}

.img-vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.img-vertical img {
  max-height: 235px;
}

/* Faq */

.faq {
  width: 100%;
  background-color: #fafafa;
  padding-bottom: 120px;
}

.container-faq {
  padding-top: 145px;
  width: 65%;
  margin: auto;
}

.title-faq h3 {
  font-size: 28px;
}

.content-faq {
  margin-top: 132px;
}

/*button-mobile-signup*/

.button-mobile-signup {
  display: none;
}

/* FOOTER */

.footer {
  width: 100%;
  background-color: #f3f3f3;
}

.container-footer {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 890px) {


  /* COURSE*/

  .image-place-course {
    display: none;
  }

  .center {
    max-width: 66%;
    min-width: 68%;
  }

  .paragraph-text {
    max-width: 474px;
  }

  .title-course {
    font-size: 25px;
  }



  .no-desktop {
    display: block;
  }

  .no-mobile {
    display: none !important;
  }

  .tag-line {
    width: 100%;
  }

  .subtitle-big-medium {
    font-size: 17px;
    margin-top: 35px;
  }

  .container-button-booking {
    position: absolute;
    bottom: 130px;
    width: 100%;
  }

  .container-buttons-home {
    width: 55%;
  }

  .button-booking-mobile {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .container-img-about-us {
    max-width: 168px;
    margin-top: 66px;
  }

  .content-about-us {
    width: 80%;
    margin-top: 50px;
    margin-left: 0px;
  }

  .title-big {
    font-size: 46px;
    line-height: 41px;
  }

  .content-about-us p {
    padding: 64px auto auto 0px;
  }

  .container-purpose {
    margin-top: 136px;
  }

  .text-purpose {
    margin-top: -172px;
    margin-left: 13%;
  }

  .text-purpose span {
    font-size: 35px;
    display: block;
    line-height: 39px;
  }

  .container-image-purpose {
    margin-top: 70px;
    margin-left: 30px;
  }

  .container-close-purpose {
    width: 64%;
    flex-direction: column;
    margin-top: 120px;
  }

  .margin-letf-purpuse {
    margin-left: 0px;
  }

  .mobile-title {
    font-size: 35px;
    display: block;
    line-height: 39px;
    margin-left: 5%;
  }

  .content-purpose {
    margin-top: 30px;
  }

  .content-purpose p {
    margin-top: 0px;
    max-width: 490px;
  }

  /*benefits*/

  .container-benefits {
    width: 60%;
  }
  .container-title h3 {
    font-size: 35px;
  }

  .content-benefit {
    width: 100%;
    margin: auto;
  }

  .right-align {
    align-items: flex-start;
  }

  .right-align h4 {
    text-align: left;
  }

  .right-align p {
    text-align: left;
  }
}

@media only screen and (max-width: 538px) {
  .container-buttons-home {
    width: 90%;
    margin-bottom: 200px;
  }

  .content-box-course {
    display: block;
  }

  .center {
    max-width: 90%;
  }

  .mleft-button {
    margin-left: 15px;
    margin-top: 15px;
  }

  .content-course {
    padding-top: 42px;
  }


  .container-footer {
    display: block;
    
  }

  .m-top-redes {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 410px) {
  .container-nav-header {
    width: 87%;
    align-items: center;
  }

  

  .button-desk-signup {
    display: none;
  }

  .button-mobile-signup {
    display: flex;
    padding-top: 7px;
    width: 38px;
    height: 38px;
  }

  .buttons-actionables {
    display: flex;
    align-items: center;
  }

  .button-primary-black-small {
    width: 85px !important;
    height: 42px !important;
    font-size: 11.5px !important;
  }

  .content-principal {
    width: 85%;
    margin-top: 20%;
    height: 100vh;
  }

  .title-medium-big {
    font-size: 25px;
    text-align: center;
  }

  .content-about-us {
    width: 95%;
  }

  .title-big {
    font-size: 31px;
    line-height: 40px;
  }

  .content-about-us p {
    margin: 40px auto auto 0px;
    font-size: 14px;
    line-height: 30px;
  }

  .container-purpose {
    margin-top: 110px;
    width: 100%;
    height: 566px;
  }

  .text-purpose {
    max-width: 213px;
    margin-left: 52px;
    margin-top: -172px;
  }

  .container-close-purpose {
    width: 80%;
    margin-top: 0px;
  }

  /* benefit */

  .container-benefits {
    width: 80%;
  }

  .benefits {
    margin-top: 85px;
  }

  .content-benefit {
    min-width: 270px;
  }

  .content-benefit h4 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .paragraph-content {
    font-size: 14.5px;
    line-height: 29px;
  }

  /* FAQ*/

  .container-faq {
    padding-top: 120px;
    width: 85%;
  }

  .content-faq {
    margin-top: 60px;
  }

  .title-faq h3 {
    margin-left: 3%;
    margin-bottom: 15px;
  }

  .ola {
    margin-left: 3%;
  }
}
</style>