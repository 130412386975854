<template>
  <div id="pay">
    <div class="container-pay">
      <!-- REFILL -->
      <div class="refill">

        <!-- <div
          v-for="(discount, index) in $store.state.discountVols"
          :key= index
          class="container-refill"
          @click="payWithStripe(discount.amount)"
          >
          <div class="info-refill">

            <p class="montserrat-medium gray-light total-text">TOTAL</p>
            <p class="montserrat-bold black total-number">{{ discount.amount }} EUR</p>


          </div>

          <div class="total mt-3">
            <p v-if="false" class="montserrat-semibold blue mt-2 percentage">+ {{ discount.discount }}%</p>

          </div>
        </div> -->

        <!-- Notice that recharging saldo is only available in person at following location https://maps.app.goo.gl/PRnDYeYmWbMNwtss7 -->
        <div class="container-refill" style="padding: 20px; height: 250px; width: 350px">
          <div class="info-refill">
          <p class="montserrat-bold black">Solo se puede recargar en punto físico:</p>
          <a href="https://maps.app.goo.gl/PRnDYeYmWbMNwtss7">
          <button class="maps-button">
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/aa/Google_Maps_icon_%282020%29.svg" alt="Google Maps Icon" />
          </button>
          </a>
          <p class="montserrat black" style="font-size: 14px">Calle México <br /> C.Comercial Las Pameras, Local 11 <br /> 38660 Playa de la Américas <br /> Santa Cruz de Tenerife</p>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PaymentService from "../../services/PaymentService"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

export default {
  name: "Configuracion",
  methods: {
    async payWithStripe(amount) {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      const obj = {
        amount: amount,
        user_id: this.$store.state.user._id
      }
      const response = await PaymentService.PayWithStripe(idToken, obj)
      window.location.href = response.data.url
    },
    CalculateRecharge(value, percentage) {
      let recharge = (1 + percentage / 100) * value
      return recharge.toFixed(2)
    },
  }

}


</script>

<style scoped>
.refill {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  max-width: 846px;
}

.container-refill {
  width: 90%;
  min-width: 235px;
  /* max-width: 245px; */
  margin-left: 3%;
  margin-top: 3%;
  height: 146px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px 18px 0px;
  -webkit-box-shadow: 1px 4px 17px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 4px 17px 1px rgba(0, 0, 0, 0.1);
}

.info-refill {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 4px;
}

.amount-refill {
  font-size: 18px;
}

.percentage {
  font-size: 23px;
}

.total-text {
  font-size: 11px;
}

.total-number {
  font-size: 30px;
}

.maps-button {
  background-color: #ffffff;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  /* Add shadow */
  -webkit-box-shadow: 1px 4px 17px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 4px 17px 1px rgba(0, 0, 0, 0.1);
}

.maps-button img {
  height: 20px;
}

@media only screen and (max-width: 595px) {
  .refill {
    width: 95%;
  }

  .container-refill {
    width: 95%;
    /* max-width: 328px; */
  }
}
</style>