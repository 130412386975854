<template>
  <div id="level">
    <div class="container-level">
      <p class="montserrat-medium black title-step">1. Elige un bono</p>

      <!-- 3 LEVEL COURSE dff -->
      <div v-if="$store.getters.currentCourse.classprice3" class="container-buttons-level">
        <button
          class="button-level"
          v-on:click="saveLevelSelection(3, $store.getters.currentCourse.classprice3)"
          v-bind:class="{
            bookingSelection:
              $store.state.bonoState.level &&
              $store.state.bonoState.level === 3,
          }"
        >
          {{ '3 Clases (' + $store.getters.currentCourse.classprice3 + '€)' }}
        </button>
      </div>

      <!-- 5 LEVEL COURSE  -->
      <div v-if="$store.getters.currentCourse.classprice5" class="container-buttons-level">
        <button
          class="button-level"
          v-on:click="saveLevelSelection(5, $store.getters.currentCourse.classprice5)"
          v-bind:class="{
            bookingSelection:
              $store.state.bonoState.level &&
              $store.state.bonoState.level === 5,
          }"
        >
          {{ '5 Clases (' + $store.getters.currentCourse.classprice5 + '€)' }}
        </button>
      </div>

      <!-- 10 LEVEL COURSE -->
      <div v-if="$store.getters.currentCourse.classprice10" class="container-buttons-level">
        <button
          class="button-level"
          v-on:click="saveLevelSelection(10, $store.getters.currentCourse.classprice10)"
          v-bind:class="{
            bookingSelection:
              $store.state.bonoState.level &&
              $store.state.bonoState.level === 10,
          }"
        >
          {{ '10 Clases (' + $store.getters.currentCourse.classprice10 + '€)' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    saveLevelSelection(selectedLevel, price) {
      let bonoState = this.$store.state.bonoState
      bonoState.level = selectedLevel
      bonoState.price = price
      this.$store.commit("updateBonoState", bonoState)
      this.$store.commit("incrementBonoStep")
    },
  },
};
</script>

<style scoped>
.title-step {
  font-size: 15px;
  margin-top: 60px;
}

.container-buttons-level {
  margin-top: 25px;
}

.button-level {
  background-color: #f5f3f3;
  width: 280px;
  height: 64px;
  border-radius: 2px;
  border: none;
  color: #000;
  font-family: "Montserrat SemiBold";
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
}

.button-level:hover {
  background-color: #fbdfb6;
}
</style>