import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {

  async SignupFirebase (firstname, lastname, phone, dateofbirth, email, uid) {
    return await apiClient.post('/auth/signup', {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      dateofbirth: dateofbirth,
      email: email,
      uid: uid
    })
  },

  async SignupFirebaseMentor (firstname, lastname, phone, email, uid) {
    return await apiClient.post('/auth/signup-mentor', {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      email: email,
      uid: uid
    })
  },

  async SigninFirebase (idToken) {
    // console.log('gagaga', idToken)
    return await apiClient.post('/auth/loginWithToken', {
      idToken: idToken
    })
  },

  async updateUser (idToken, user) {
    // console.log('5566', user)
    const response = await apiClient.put('/auth', user,
      {
        headers: { idToken: idToken }
      })
    return response
  },

  async updateUserAdmin (idToken, userId, user) {
    // console.log('5566', user)
    const response = await apiClient.put(`/auth/${userId}`, user,
      {
        headers: { idToken: idToken }
      })
    return response
  },

  async getUser (idToken, userId) {
    const response = await apiClient.get(`/auth/${userId}`,
      {
        headers: { idToken: idToken }
      })
    return response
  },

  // async updateSessions (idToken, active) {
  //   return await apiClient.post('/users/update',
  //     {
  //       monetization: {
  //         sessions: active
  //       }
  //     },
  //     {
  //       headers: { idToken: idToken }
  //     })
  // },

  // async getCurrentUser (idToken) {
  //   return await apiClient.get('users/current',
  //     {
  //       headers: { idToken: idToken }
  //     })
  // }
}
