<template>
  <div id="sign_up">
    
    <!-- FORM EDIT COURSE -->
    <div
      class="form-create-course">
      <div
        class="principal-info-course">
        <h3
          v-if="NameLocation"
          class="montserrat-bold black">
          {{NameLocation.toUpperCase()}}
        </h3>


      </div>
      <div
        class="info-course">
  
          <div class="container-form">

        <!-- Add new place of class -->
        <form
          class="">

          <!-- name place of class -->
          <b-field
            class="max-campos mt-3"
            label="Nombre del lugar"
            :message="mensajeNameLocation"
            :type="messageTypeNameLocation">
            
            <b-input
              v-model="NameLocation"
              v-on:focusout.native="ValidityNameLocation"
              v-on:focusin.native="clearValidityNameLocation"
              id="name"
              autocomplete="off">
            </b-input>
          </b-field>

          <!-- Ubication place of class -->

          <div
            class="max-campos is-flex">

            <b-field
              class=""
              label="Longitud"
              :message="mensajeLongitud"
              :type="messageTypeLongitud"
              >

              <b-input
                v-model="Longitud"
                v-on:focusout.native="ValidityLongitud"
                v-on:focusin.native="clearValidityLongitud"
                id="Longitud"
                autocomplete="off">
              </b-input>
            </b-field>

            <b-field
              class="ml-2"
              label="Latitud"
              :message="mensajeLatitud"
              :type="messageTypeLatitud">
              <b-input
                v-model="Latitud"
                v-on:focusout.native="ValidityLatitud"
                v-on:focusin.native="clearValidityLatitud"
                id="leatitudePlace"
                autocomplete="off">
              </b-input>
            </b-field>
          </div>

          <!-- description place of class -->
          <b-field
            class="max-campos mt-2"
            label="Descripción del lugar">

            <b-input
              v-model="Description"
              v-on:focusout.native="ValidityDescription"
              v-on:focusin.native="clearValidityDescription"
              id="description"
              type="textarea"
              maxlength="200"
              autocomplete="off"
            ></b-input>

          </b-field>

          <!-- Upload image  of the class place -->
          <div
            class="upload-image">

            <div
              class="container-button-upload">
              
            <div class="image-profile mb-5">
              <img
                v-if="$store.state.currentLocation"
                class=""
                :src="$store.state.currentLocation.picture"
                width=500
              >
            </div>

              <a
                @click="uploadImageModal"
                class="montserrat-medium orange text-underline">
                Subir nueva foto del lugar</a>
            </div>
          </div>
        </form>

          </div>
      </div>
    </div>

    <div class="container-buttons">
      <button
        @click="backToLocations"
        class="button-medium-stroke-black">Cancelar</button>
      <button
        @click="updateLocation"
        class="button-primary-extra-medium ml-2">Guardar</button>
    </div>

  </div>


</template>

<script>

import LocationService from '@/services/LocationService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'UpdateLocation',
  components: {
  },
  data () {
    return {
      NameLocation: null,
      Description: null,
      Longitud: null,
      Latitud: null,
      Picture: null,

      mensajeNameLocation: '',
      mensajeDescription: '',
      mensajeLongitud: '',
      mensajeLatitud: '',

      messageTypeNameLocation: '',
      messageTypeDescription: '',
      messageTypeLongitud: '',
      messageTypeLatitud: '',

      valid: true,
      mensaje: '',
      loader: false,
    }
  },

  // CREATED
  async created () {
    await LocationService.GetLocation(window.location.pathname.split('/')[3])
      .then(response => {
        if (response.status === 200) {
          const currentLocation = response.data[0]
          this.NameLocation= currentLocation.name,
          this.Description= currentLocation.description,
          this.Longitud= currentLocation.longitud,
          this.Latitud= currentLocation.latitud,
          this.Picture= currentLocation.picture
        }
      })
      .catch(()=>{})
    this.$store.commit('updateLocation', window.location.pathname.split('/')[3])
  },

  // METHODS
  methods: {

    // Upload image
    uploadImageModal () {
      this.$store.commit('openModal', 'uploadimage')
    },

    // Close modal
    backToLocations () {
      this.$router.push('/admin/lugares').catch(()=>{}) 
      this.$store.commit('clearCurrentLocation')
    },

    // New Location --------
    async updateLocation () {
      if (this.NameLocation && this.Description && this.Longitud && this.Latitud) {
        this.valid = true
      }
      await this.ValidityNameLocation()
      await this.ValidityDescription()
      await this.ValidityLongitud()
      await this.ValidityLatitud()


      if (this.valid) {
        // console.log('jhkdjhdk')
        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const updateLocation = await LocationService.UpdateLocation( idToken, {
          name: this.NameLocation,
          description: this.Description,
          latitud: this.Latitud,
          longitud: this.Longitud
        }, this.$store.state.currentLocation._id)

        if (updateLocation.status === 200) {
          this.$store.commit('updateLocations')
          this.success('Guardado')
          this.$router.push('/admin/lugares').catch(()=>{})
        }

      }
    },

    ValidityNameLocation () {
      if (!this.NameLocation) {
        this.mensajeNameLocation = 'Cuál es el nombre del lugar?'
        this.messageTypeNameLocation = 'is-primary'
        this.valid = false
      } else {
        this.mensajeNameLocation = ''
      }
    },

    ValidityDescription () {
      if (!this.Description) {
        this.mensajeDescription = 'Cuál es la descripción del lugar?'
        this.messageTypeDescription = 'is-primary'
        this.valid = false
      } else {
        this.mensajeDescription = ''
      }
    },

    ValidityLongitud () {
      if (!this.Longitud) {
        this.mensajeLongitud = 'Cuál es la longitud?'
        this.messageTypeLongitud = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLongitud = ''
      }
    },

    ValidityLatitud () {
      if (!this.Latitud) {
        this.mensajeLatitud = 'Cuál es la latitud?'
        this.messageTypeLoatitude = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLatitud = ''
      }
    },
    

    clearValidityNameLocation () {
      this.messageTypeNameLocation = ''
      this.mensajeNameLocation = ''
    },

    clearValidityDescription () {
      this.messageTypeDescription = ''
      this.mensajeDescription = ''
    },

    clearValidityLongitud () {
      this.messageTypeLongitud = ''
      this.mensajeLongitud = ''
    },

    clearValidityLatitud () {
      this.messageTypeLatitud = ''
      this.mensajeLatitud = ''
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  }
}


</script>


<style scoped>

.max-campos {
  max-width: 430px;
}

.image-profile img {
  height: 300px;
  object-fit: cover;
}

.is-rounded {
  border-radius: 100px;
}

.header-modal {
  width: 100%;
  background-color: #EFEFEF;
  height: 72px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.form-create-course {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 104px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.principal-info-course {
  width: 90%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ECECEC;
}

.status-course {
  text-align: right;
}

.status-course p {
  font-size: 14px;
}

.small-text {
  font-size: 12px;
}

.info-course {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.container-form {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.letf {
  max-width: 410px;
}

.name-course {
  min-width: 270px;
}

.date-course {
  display: flex;
}

.end-date {
  margin-left: 10px;
}

.max-student {
  margin-left: 7px;
}

.pricing-students {
  display: flex;
}

.mentor-place {
  display: flex;
}

.place {
  margin-left: 10px;
}

.container-buttons {
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 40px;
  padding-bottom: 35px;
}

.text-underline {
  font-size: 14px;
}


@media only screen and (max-width: 1120px) {
  .letf {
    max-width: 390px;
    margin-right: 10px;
  }

  .rigth {
    min-width: 239px;
  }

  .container-form {
    width: 100%;
  }
}


@media only screen and (max-width: 871px) {
  .container-form {
    display: block;
    width: 98%;
    margin: auto;
  }

  .right {
    display: flex;
    margin-top: 8px;
  }

  .level-course {
    margin-left: 10px;
  }

}

@media only screen and (max-width: 607px) {

  .form-create-course {
    width: 85%;
  }
}


@media only screen and (max-width: 513px) {

  .container-header-modal {
    width: 90%;
  }

  .container-header-modal h3 {
    font-size: 16px;
  }

  .form-create-course {
    width: 95%;
  }

  .button-close {
    width: 18px;
    height: 18px;
  }


  .no-mobile {
    display: none;
  }

  .date-course {
    display: block;
  }

  .end-date {
    margin-left: 0px;
  }
  
  .max-student {
    margin-left: 0px; 
  }

  .pricing-students {
    display: block;
    margin-top: 12px;
  }

  .mentor-place {
    
    margin-top: 12px;
  }

  .place {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .form-create-course {
    width: 93%;
    margin-top: 83px;
  }

  

  .image-profile img {
    height: 171px;
  }

  .header-modal {
    height: 60px;
  }

  .letf {
    width: 100%;
    margin-right: 0px;
  }

  .container-buttons {
    width: 90%;
    padding-bottom: 30px;
    margin-top: 20px;
  }

}
</style>
