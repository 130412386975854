<template>
  <div id="course">
    <div class="container-gray-light"></div>
    <div class="container-info-course">
      <div class="info-course">
        <!-- TOP -->
        <div class="principal-info-course">
          <h3 class="montserrat-bold black">Información</h3>

          <div class="status-course">
            <p class="OpenSans-Bold gray-dark small-text no-mobile">
              ESTADO DEL CURSO
            </p>

            <!-- STATUS COURSE -->
            <p
              v-if="$store.getters.currentCourse" 
              class="OpenSans-Bold orange text-tranformation-uppercase">
              {{ $store.getters.currentCourse.active ? "ACTIVO" : "INACTIVO" }}
            </p>
          </div>
        </div>

        <!-- FORM EDIT COURSE -->
        <div class="container-form">
          <div class="letf">
            <!-- NAME COURSE -->
            <b-field label="Nombre curso">
              <b-input
                v-model="courseName"
                id="name"
                autocomplete="off"
              >
              </b-input>
            </b-field>

            <div class="date-course">
              <!-- DATE START COURSE -->
              <b-field label="Fecha de inicio">
                <b-datepicker
                  v-model="startcourse"
                  :show-week-number="showWeekNumber"
                  placeholder="Seleccionar fecha"
                  icon="calendar-today"
                  :icon-right="startcourse ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="clearDate"
                  trap-focus
                  disabled
                >
                </b-datepicker>
              </b-field>

              <!-- DATE END COURSE -->
              <b-field class="date-end" label="Fecha final">
                <b-datepicker
                  v-model="endcourse"
                  :show-week-number="showWeekNumber"
                  placeholder="Seleccionar fecha"
                  icon="calendar-today"
                  :icon-right="endcourse ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="clearDate"
                  trap-focus
                  disabled
                >
                </b-datepicker>
              </b-field>
            </div>

            <div class="pricing-students">
              <!-- PRICING OF COURSE -->
              <b-field class="pricing" label="Costo del curso">
                <b-input
                  type="number"
                  placeholder="EUR"
                  v-model="$store.getters.currentCourse.classprice"
                  disabled
                >
                </b-input>
              </b-field>


              <!-- MAX STUDENTS -->
              <b-field class="max-student" label="Max de estudiantes">
                <b-input
                  type="number"
                  v-model="$store.getters.currentCourse.maxstudents"
                  disabled
                >
                </b-input>
              </b-field>
            </div>

            <b-field class="mt-5 coursetype-container">
              <b-radio v-model="coursetype"
                name="coursetype"
                style="color: #363636;font-size:16px !important;"
                native-value="groupcourse"
                disabled>
                  Grupo de clases
              </b-radio>

              <b-radio v-model="coursetype"
                class="ml-3"
                name="coursetype"
                style="color: #363636;font-size:16px !important;"
                native-value="campamento"
                disabled>
                Campamento
              </b-radio>
            </b-field>

            <div class="pricing-students-classes mt-5">
              <!-- PRICING OF COURSE -->
              <b-field class="pricing" label="Precio de los bonos de 3, 5 y 10 clases" v-if="coursetype == 'groupcourse'">
                <b-input
                  type="number"
                  class="mr-3 mt-2"
                  v-model="$store.getters.currentCourse.classprice3"
                  disabled
                >
                </b-input>
                <b-input
                  type="number"
                  class="mr-3 mt-2"
                  v-model="$store.getters.currentCourse.classprice5"
                  disabled
                >
                </b-input>
 
                <b-input
                  type="number"
                  v-model="$store.getters.currentCourse.classprice10"
                  disabled
                  class="mr-3 mt-2"
                >
                </b-input>
              </b-field>

              <b-field
                  class="pricing"
                  label="Precio por 1, 2, 3 o 4 semanas"
                  v-if="coursetype == 'campamento'">

                  <b-input
                    v-model="$store.getters.currentCourse.weekprice1"
                    type="number"
                    class="mr-2"
                    disabled>
                  >
                  </b-input>

                  <b-input
                    v-model="$store.getters.currentCourse.weekprice2"
                    type="number"
                    class="mr-2"
                    disabled>
                  >
                  </b-input>

                  <b-input
                    v-model="$store.getters.currentCourse.weekprice3"
                    type="number"
                    class="mr-2"
                    disabled>
                  >
                  </b-input>
                  <b-input
                    v-model="$store.getters.currentCourse.weekprice4"
                    type="number"
                    disabled>
                  >
                  </b-input>
                </b-field>
            </div>

            <div class="mentor-place mt-4">
              <!-- MENTOR -->
              <b-field class="mentor" label="Mentor">
                <b-select
                  :placeholder="$store.getters.currentCourse.mentor.firstname +  ' ' +  $store.getters.currentCourse.mentor.lastname"
                  disabled
                >
                  <option
                    v-for="(profesor, idx) in profesores"
                    :value="profesor._id"
                    :key="idx"
                  >
                    {{ profesor.firstname + " " + profesor.lastname }}
                  </option>
                </b-select>
              </b-field>

              <!-- PLACE -->
              <b-field class="place ml-2" label="Lugar de clase">
                <b-select
                  :placeholder="$store.getters.currentCourse.location.name"
                  disabled
                >
                  <option
                    v-for="(location, idx) in locations"
                    :value="location._id"
                    :key="idx"
                  >
                    {{ location.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- DESCUENTO FEDERADOS Y RESIDENTES -->
              <div class="is-flex percentaje-field mt-4">
                <b-field label="Descuento">
                  <b-select 
                      v-model="typeSelected" 
                      placeholder=""
                      disabled>
                      <option value="Residente">
                        Residente
                      </option>
                      <option value="Federado">
                        Federado
                      </option>
                  </b-select>
                </b-field>
                <b-field class="percentaje-label ml-4" label="Percentaje">
                  <b-input
                      v-model="discount"
                      type="number"
                      placeholder="DCT %"
                      disabled>
                    >
                    </b-input>
                </b-field>
              </div>
             <!-- CONDICIONES DEL CURSO -->
              <b-field
                class="mt-5"
                label="Condiciones">
                <b-input
                  v-model="conditions"
                  type="textarea"
                  placeholder="Los condiciones del Curso"></b-input>
              </b-field>
              
          </div>

          <div class="right">
            <!-- STATUS COURSE -->
              <b-field label="Estado del curso">
                <b-select
                  v-model="active" 
                  placeholder="Seleccionar">
                  <option :value=true> Activo </option>
                  <option :value=false> Inactivo </option>
                </b-select>
              </b-field>

            <!-- NIVEL -->
            <b-field class="level-course" label="Nivel">
              <b-select
                :placeholder="$store.getters.currentCourse.skilllevel"
                disabled
              >
                <option
                  v-for="(level, idx) in skillLevels"
                  :value="level"
                  :key="idx"
                >
                  {{ level }}
                </option>
              </b-select>
            </b-field>
          </div>

          
        </div>

        <div class="container-buttons-save">
          <button
          @click.prevent="updateCourse" class="mt-4 button-primary-medium">
          Guardar</button>
        </div>
      </div>

      <!-- SCHEDULE COURSE -->
      <div class="schedule-course">
        <div class="container-calendar">
          <div class="header-schedule">
            <h3 class="montserrat-bold black">Horario del curso</h3>
          </div>

          <!-- COMPONENT CALENDAR -->
          <div>
            <Calendar></Calendar>
          </div>
        </div>
      </div>

      <!-- CLASSES OF COURSE -->
      <div class="container-classes-course">
        <div class="header-section">
          <h3 class="montserrat-bold black">Clases</h3>
          <button @click="newClass" class="button-primary-medium">
            + nueva clase
          </button>
        </div>

        <!-- INFO CLASSES -->
        <div
          v-for="(clase, index) in $store.getters.courseClassesFormated"
          :key="index"
          class="classes-content"
        >
          <div class="info-classes-principal">
            <div class="schedule-class">
              <!-- DATE CLASS -->
              <span class="montserrat-semibold black is-size-text-normal">
                {{ clase.startclass + "," }}
              </span>

              <!-- HOUR CLASS -->
              <span class="montserrat text-tranformation-uppercase is-size-text-normal">
                {{ clase.startclassHour + " " }} -
                {{ clase.endclassHour + " " }}
              </span>
            </div>

            <!-- LEVEL CLASS -->
            <p class="opensans nivel mt-5 is-size-text-normal">
              {{ clase.skilllevel }}
            </p>

            <div class="info-complementary-class">
              <div class="is-flex">
                <!-- MENTOR CLASS -->
                <span class="OpenSans-SemiBold black is-size-text-normal"> Mentor:</span>
                <span class="opensans ml-2 is-size-text-normal"> {{ clase.mentor }}</span>
              </div>

              <div class="is-flex mleft">
                <span class="OpenSans-SemiBold black is-size-text-normal"> Lugar: </span>

                <!-- PLACE -->
                <span class="opensans ml-2 is-size-text-normal">
                  {{ clase.location }}
                </span>
              </div>
            </div>
          </div>

          <!-- BUTTONS CLASSES -->
          <div class="container-buttons top-button">
           <!-- EDIT COURSE -->
            <button
              @click.prevent="changeClass(clase._id)" class="button-medium-stroke top-button mr-1">
              Editar</button>
            <button @click.prevent="cancelClass(clase._id)" class="button-medium-stroke top-button">
              Cancelar Clase
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "@/components/adminCursos/calendar.vue";
import dayjs from "dayjs";
import firebase from "firebase/compat/app"
import ClassService from "@/services/ClassService";
import CourseService from "@/services/CourseService";

export default {
  name: "EditCourse",
  components: {
    Calendar,
  },

  data() {
    return {
      name: "",
      locations: this.$store.state.locations,
      allProfiles: this.$store.state.allProfiles,
      skillLevels: this.$store.state.skilllevels,
      showWeekNumber: false,
      conditions: null,
      courseName: null,
      typeSelected: null,
      discount: null,
      coursetype: null,
      active: true
    };
  },

  computed: {
    profesores: function () {
      return this.allProfiles.filter(
        (profile) => profile.isMentor && profile.status === "active"
      );
    },

    startcourse: function () {
      return dayjs(this.$store.getters.currentCourse.startcourse).toDate();
    },

    endcourse: function () {
      return dayjs(this.$store.getters.currentCourse.endcourse).toDate();
    },
  },

  // CREATED
  created() {
    this.$store.commit(
      "updateCourseClasses",
      window.location.pathname.split("/")[3]
    );

    ClassService.GetClass()
      .then(response => {
      if (response.status === 200) this.$store.commit('updateAllClasses', response.data) 
    })
  },

  mounted() {
    window.scrollTo(0, 0);
    this.$store.commit(
      "updateCurrentCourse",
      window.location.pathname.split("/")[3]
    );
    this.courseName = this.$store.getters.currentCourse.name
    this.conditions = this.$store.getters.currentCourse.conditions
    this.active = this.$store.getters.currentCourse.active
    this.typeSelected = this.$store.getters.currentCourse.residentDiscount > 0 ? "Residente" : this.$store.getters.currentCourse.federadoDiscount > 0 ? "Federado" : ""
    this.discount = this.$store.getters.currentCourse.residentDiscount > 0 ? this.$store.getters.currentCourse.residentDiscount : this.$store.getters.currentCourse.federadoDiscount > 0 ? this.$store.getters.currentCourse.federadoDiscount : 0
    this.coursetype = this.$store.getters.currentCourse.coursetype
 },

  methods: {
    ValidityNameCourse() {
      if (!this.name) {
        this.mensajeNameCourse = "Cuál es el nombre del curso?";
        this.messageTypeNameCourse = "is-primary";
        this.valid = false;
      } else {
        this.mensajeName = "";
      }
    },

    clearDate() {
      this.selected = null;
    },

    // New Class
    newClass() {
      this.$store.commit("openModal", "newclass");
    },

    // Change Course properties
    async updateCourse() {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const updateCourse = await CourseService.UpdateCourse(idToken, {
        name: this.courseName,
        conditions: this.conditions,
        active: this.active,
      }, this.$store.getters.currentCourse._id)

      if (updateCourse.status === 200) {
          this.$store.commit('updateCourses')
          this.success('Guardado')
          .catch((err)=>{this.danger(err)})
        }
    },
    
    // Danger Toast
    danger(msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: "is-primary",
      });
    },
    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Change class time
    changeClass(id) {
      let selectedClass = this.$store.getters.getClass(id)
      this.$store.commit('selectedClass', selectedClass);
      this.$store.commit("openModal", "changeclass");
    },

    // Cancel class
    cancelClass(id) {
      let selectedClass = this.$store.getters.getClass(id)
      this.$store.commit('selectedClass', selectedClass);
      this.$store.commit("openModal", "cancelclass");
    },
  },

  destroyed() {
    this.$store.commit("updateCourseClasses");
  },
};
</script>
  

<style scoped>
#course {
  background-color: #fafafa;
  height: 100%;
  padding-top: 211px;
  padding-bottom: 54px;
}

.container-gray-light {
  background-color: #efefef;
}

.info-course {
  background-color: #fff;
  border: 1px solid #ededed;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: -92px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.principal-info-course {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.status-course {
  text-align: right;
}

.container-form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.principal-info-course {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
}

.principal-info-course h3 {
  font-size: 21px;
}

.status-course p {
  font-size: 15px;
}

.small-text {
  font-size: 12px !important;
}

.container-form {
  width: 80%;
  margin: auto;
  margin-top: 36px;
}

.letf {
  max-width: 410px;
  margin-right: 10px;
}

.date-course {
  display: flex;
}

.date-end {
  margin-left: 10px;
}

.pricing-students {
  display: flex;
}

.mentor-place {
  display: flex;
}

.max-student {
  margin-left: 10px;
}

.m-left-input {
  margin-left: 5px;
}

.schedule-course {
  background-color: #fff;
  border: 1px solid #ededed;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 20px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.header-schedule {
  border-bottom: 1px solid #ececec;
  padding-bottom: 40px;
}

.container-calendar {
  width: 85%;
  margin: auto;
  margin-top: 50px;
}

.container-calendar h3 {
  font-size: 21px;
}

.container-calendar p {
  max-width: 548px;
}

.container-classes-course {
  background-color: #fff;
  border: 1px solid #ededed;
  width: 70%;
  /*height: 100vh;*/
  max-width: 882px;
  margin: auto;
  margin-top: 20px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.header-section {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  padding-bottom: 23px;
  border-bottom: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classes-content {
  display: flex;
  background-color: #f5f3f3;
  width: 90%;
  margin: auto;
  margin-top: 15px;
  justify-content: space-between;
  padding: 13px 27px 12px 39px;
}

.top-button {
  margin-top: 15px;
}

.info-complementary-class {
  display: flex;
  margin-top: 10px;
}

.mleft {
  margin-left: 15px;
}

.container-buttons-save {
  width: 80%;
  max-width: 882px;
  margin: auto;
  margin-top: 40px;
  
}

@media only screen and (max-width: 876px) {
  .container-form {
    width: 90%;
    display: block;
  }

  .letf {
    max-width: 415px;
  }

  .right {
    display: flex;
  }

  .level-course {
    margin-left: 10px;
  }

  .classes-content {
    display: block;
  }

  .buttons-classes {
    display: flex;
    margin-top: 20px;
  }

  .top-button {
    margin-top: 10px;
  }

  .info-complementary-class {
    display: block;
    margin-top: 7px;
  }

  .mleft {
    margin-left: 0px;
    margin-top: 8px;
  }

  .m-left-input {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 565px) {
  .info-course {
    width: 90%;
  }

  .no-mobile {
    display: none;
  }

  .schedule-course {
    width: 90%;
  }

  .container-classes-course {
    width: 90%;
  }

  .info-course {
    margin-top: -129px;
  }

  .level-course {
    margin-left: 0px;
  }

  .coursetype-container {
    margin-top: 42px !important;
  }
}

@media only screen and (max-width: 460px) {
  .container-form {
    width: 93%;
    margin-left: 5%;
  }

  .pricing-students {
    display: block;
    margin-top: 20px;
  }

  .date-course {
    display: block;
  }

  .date-end {
    margin-left: 0px;
  }

  .mentor-place {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .place {
    margin-top: 9px;
    margin-left: 0px !important;
  }

  .max-student {
    margin-left: 0px;
  }

  .right {
    margin-top: 30px;
  }

  .classes-content {
    padding: 13px 18px 12px 18px;
  }

  .container-buttons-save {
    width: 90%;
    padding-bottom: 30px;
    margin-top: 20px;
  }

  .pricing-students-classes {
    margin-top: 40px !important;
    margin-bottom: 30px;
  }

  .percentaje-field {
    margin-top: 30px !important;
  }
}
</style>