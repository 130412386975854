<template>
  <div id="confirmation">
    <p class="montserrat-medium black title-step">
      Verifica los datos de tu bono
    </p>
    <div class="space-45"></div>
    <div class="container-confirmation">


      <!-- NAME COURSE -->
      <div class="student-container">
        <span class="opensans black"> Curso:</span>
        <span class="OpenSans-SemiBold black ml-1">
          {{ $store.state.bonoState.course.name }}</span
        >
      </div>


      <!-- NAME STUDENT -->
      <div class="student-container">
        <span class="opensans black"> Estudiante:</span>
        <span class="OpenSans-SemiBold black ml-1">
          {{
            $store.state.bonoState.student.firstname +
            " " +
            $store.state.bonoState.student.lastname
          }}</span
        >
      </div>

      <div class="info-complementary">
        <div>
          <span class="opensans black"> Número de clases:</span>

          <!-- LEVEL-->
          <span class="OpenSans-SemiBold black ml-1">
            {{ $store.state.bonoState.level }}</span
          >
        </div>

        <div class="pricing-container">
          <span class="opensans black"> Precio:</span>
          <!-- PRICING CLASS -->
          <span v-if="$store.state.bonoState.course.residentDiscount > 0 && $store.state.bonoState.student.residente" class="OpenSans-SemiBold black ml-1">
            {{ calculatePrice($store.state.bonoState.course, $store.state.bonoState.student, $store.state.bonoState.price) + '€' + ' (' + $store.state.bonoState.course.residentDiscount + '% descuento residente)'}}
          </span>
          <span v-else-if="$store.state.bonoState.course.federadoDiscount > 0 && $store.state.bonoState.student.federado" class="OpenSans-SemiBold black ml-1">
            {{ calculatePrice($store.state.bonoState.course, $store.state.bonoState.student, $store.state.bonoState.price) + '€' + ' (' + $store.state.bonoState.course.federadoDiscount + '% descuento federado)'}}
          </span>
          <span v-else class="OpenSans-SemiBold black ml-1">
            {{$store.state.bonoState.price}}€ sin descuento
          </span>
        </div>
      </div>

    </div>
    <button class="button-primary-medium" v-on:click="newBono">
      Comprar bono
    </button>
  </div>
</template>

<script>
import BonoService from '@/services/BonoService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  methods: {
    confirmReservation() {
        this.$store.commit('incrementBookingStep')
        this.$store.commit('reservationSuccessfull')
    },

    calculatePrice(course, profile, defaultPrice) {
      // console.log(course)
      if (course.residentDiscount > 0 && profile.residente) return defaultPrice*((100-course.residentDiscount)/100)
      if (course.federadoDiscount > 0 && profile.federado) return defaultPrice*((100-course.federadoDiscount)/100)
      else return defaultPrice
    },

    async newBono () {
      const newBonoObj = {
        student_id: this.$store.state.bonoState.student._id,
        max_bookings: this.$store.state.bonoState.level,
        course_id: this.$store.state.bonoState.course._id,
      }
      // console.log(newBonoObj)

      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await BonoService.NewBono(idToken, newBonoObj)
      console.log('------> NewBono', response)

      if (response.status === 200 && response.data.msg === 'No tienes saldo suficiente para comprar el bono') {
        this.$store.commit('closeModalR')
        this.$store.commit('clearBonoDetails')
        this.$store.commit('updateUserDB')
        this.$store.commit('openModal', 'addcredits')
      } else if (response.status === 200) {
        this.danger(response.data.msg)    
      } else if (response.status === 201) {
        this.success('Bono adquirido')
        this.$store.commit('closeModalR')
        this.$store.commit('clearBonoDetails')
        this.$store.commit('updateUserDB')
        this.$store.commit('updateBonos')
      }

    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

  }
}
</script>

<style scoped>
.title-step {
  font-size: 15px;
  margin-top: 60px;
  margin-top: 50px;
}

.space-45 {
  height: 45px;
}

.container-confirmation {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 2px;
  max-width: 403px;
  padding: 22px 17px 22px 23px;
}

.date-class-reserved span {
  font-size: 15px;
}

.course-title {
  margin-top: 8px;
  font-size: 13px;
}

.student-container {
  margin-top: 8px;
}

.student-container span {
  font-size: 15px;
}

.info-complementary {
  font-size: 15px;
  border-top: 1px solid #ececec;
  margin-top: 13px;
  padding-top: 10px;
}

.pricing-container {
  margin-top: 8px;
}

.button-primary-medium {
  margin-top: 20px;
}
</style>