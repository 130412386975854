<template>
  <div id="confirmation">
    <div class="space-45"></div>
    <div class="container-confirmation">
      <!-- DATE CLASS -->
      <div class="date-class-reserved">
        <span class="montserrat-bold black">{{
          $store.getters.currentClassFormated.startclass
        }}</span>
        <br />
        <span class="montserrat black">
          {{
            $store.getters.currentClassFormated.startclassHour +
            " - " +
            $store.getters.currentClassFormated.endclassHour
          }}</span
        >
      </div>
      <br />

      <!-- NAME COURSE -->
      <span class="opensans black"> Curso:</span>
      <span class="OpenSans-SemiBold black ml-1">
        {{ $store.state.reservationState.course.name }}
      </span>

      <!-- NAME STUDENT -->
      <div class="student-container">
        <span class="opensans black"> Estudiante:</span>
        <span class="OpenSans-SemiBold black ml-1">
          {{
            $store.state.reservationState.student.firstname +
            " " +
            $store.state.reservationState.student.lastname
          }}</span
        >
      </div>

      <!-- PLACES AVAILABLE -->
      <div class="places-container">
        <span class="opensans black"> Reservas:</span>
        <span class="OpenSans-SemiBold black ml-1">
          {{ $store.getters.currentClassFormated.bookings }}/{{
            $store.getters.currentClassFormated.maxstudents
          }}
        </span>
      </div>

      <div class="info-complementary">
        <!-- <div>
          <span class="opensans black"> Nivel:</span>

          <span class="OpenSans-SemiBold black ml-1">
            {{ $store.state.reservationState.course.skilllevel }}</span
          >
        </div> -->

        <div class="pricing-container">
          <span class="opensans black"> Precio:</span>
          <!-- PRICING CLASS -->
          <span
            v-if="!$store.state.reservationState.with_bono"
            class="OpenSans-SemiBold black ml-1"
          >
            {{
              calculatePrice(
                $store.state.reservationState.course,
                $store.state.reservationState.student
              ) + "€"
            }}
          </span>
          <span
            v-if="$store.state.reservationState.with_bono"
            class="OpenSans-SemiBold black ml-1"
          >
            1 bono
          </span>
        </div>
      </div>
      <b-message
        type="is-info"
        has-icon
        class="OpenSans light"
        style="margin-top: 8px"
      >
        Recuerda revisar el horario y el lugar antes de ir a clase, ya que puede
        cambiar dependiendo de las condiciones climáticas.
        <span style="font-style: italic">{{
          this.$store.state.reservationState.level
        }}</span>
      </b-message>
    </div>
    <button class="button-primary-medium" v-on:click="newBooking">
      Reservar
    </button>
  </div>
</template>

<script>
  import BookingService from "@/services/BookingService";
  import firebase from "firebase/compat/app";
  import "firebase/compat/auth";

  export default {
    name: "confirmation",
    async mounted() {
      const availableBonoArr = await this.$store.state.mybonos.filter(
        (bono) =>
          bono.course_id._id.toString() ===
            this.$store.state.reservationState.course._id.toString() &&
          bono.profile_id._id.toString() ===
            this.$store.state.reservationState.student._id.toString() &&
          bono.bookings.length < bono.max_bookings
      );
      // console.log('availableBonoArr', availableBonoArr)

      const availableBono = availableBonoArr[0];

      if (availableBonoArr.length > 0) {
        this.$store.state.reservationState.with_bono = true;
        this.$store.state.reservationState.bono_id = availableBono._id;
      } else {
        this.$store.state.reservationState.with_bono = false;
        this.$store.state.reservationState.price =
          this.$store.state.reservationState.course.classprice;
      }
    },
    methods: {
      confirmReservation() {
        this.$store.commit("incrementBookingStep");
        this.$store.commit("reservationSuccessfull");
      },

      calculatePrice(course, profile) {
        if (course.residentDiscount > 0 && profile.residente)
          return course.classprice * ((100 - course.residentDiscount) / 100);
        if (course.federadoDiscount > 0 && profile.federado)
          return course.classprice * ((100 - course.federadoDiscount) / 100);
        else return course.classprice;
      },

      async newBooking() {
        const newBookingObj = {
          class_id: this.$store.state.reservationState.class_id,
          profile_id: this.$store.state.reservationState.student._id,
          price: this.$store.state.reservationState.price,
          with_bono: this.$store.state.reservationState.with_bono,
          bono_id: this.$store.state.reservationState.bono_id,
        };
        // console.log(newBookingObj)

        const idToken = await firebase.auth().currentUser.getIdToken(true);

        if (this.$store.state.reservationState.with_bono) {
          const response = await BookingService.NewBookingBono(
            idToken,
            newBookingObj
          );
          // console.log('------> NewBookingBono', response)

          if (response.status === 200) {
            this.danger(response.data.msg);
          } else if (response.status === 201) {
            this.success("Reserva realizada");
            this.$store.commit("closeModalR");
            this.$store.commit("clearBookingDetails");
            this.$store.commit("updateUserDB");
            this.$store.commit("updateBonos");
          }
        } else {
          const response = await BookingService.NewBooking(
            idToken,
            newBookingObj
          );
          // console.log('------> NewBooking', response)

          if (
            response.status === 200 &&
            response.data.msg === "No tienes saldo suficiente para reservar"
          ) {
            this.$store.commit("closeModalR");
            this.$store.commit("clearBookingDetails");
            this.$store.commit("updateUserDB");
            this.$store.commit("openModal", "addcredits");
          } else if (response.status === 200) {
            this.danger(response.data.msg);
          } else if (response.status === 201) {
            this.success("Reserva realizada");
            this.$store.commit("closeModalR");
            this.$store.commit("clearBookingDetails");
            this.$store.commit("updateUserDB");
          }
        }
      },

      // Danger Toast
      danger(msg) {
        this.$buefy.toast.open({
          duration: 5000,
          message: msg,
          position: "is-bottom",
          type: "is-primary",
        });
      },

      // Success Toast
      success(msg) {
        this.$buefy.toast.open({
          duration: 4000,
          message: msg,
          position: "is-top",
          type: "is-success",
        });
      },
    },
  };
</script>

<style scoped>
  .title-step {
    font-size: 15px;
    margin-top: 50px;
  }

  .space-45 {
    height: 45px;
  }

  .container-confirmation {
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 2px;
    max-width: 403px;
    padding: 22px 17px 22px 23px;
  }

  .date-class-reserved span {
    font-size: 15px;
  }

  .course-title {
    margin-top: 8px;
    font-size: 13px;
  }

  .student-container {
    margin-top: 8px;
  }

  .student-container span {
    font-size: 15px;
  }

  .info-complementary {
    font-size: 15px;
    border-top: 1px solid #ececec;
    margin-top: 13px;
    padding-top: 10px;
  }

  .pricing-container {
    margin-top: 8px;
  }

  .button-primary-medium {
    margin-top: 20px;
  }
</style>
