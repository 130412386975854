<template>
  <div id="new-course">

    <div class="header-modal">

      <!-- TOP MODAL -->
      <div class="container-header-modal">
        <h3 class="montserrat-bold black">Nueva Curso</h3>
        
        <!-- BUTTON CLOSE -->
        <a @click="closeModal">
          <div class="">
            <svg class="button-close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 2L2 18" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 2L18 18" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </div>
    </div>
    
    <!-- FORM EDIT COURSE -->
    <div
      class="form-create-course">
      <div
        class="principal-info-course">
        <h3
          class="montserrat-bold black">
          Información
        </h3>

        <div class="status-course">
          <p
          class="OpenSans-Bold gray-dark small-text no-mobile">
            ESTADO  DEL  CURSO</p>

          <!-- STATUS COURSE -->
          <p
            class="OpenSans-Bold orange text-tranformation-uppercase">
            NUEVO</p>
        </div>
      </div>
      <div
        class="info-course">
  
          <div class="container-form">

            <div class="letf">

              <!-- NAME -->
              <b-field
                class="name-course"
                label="Nombre curso"
                :message="mensajeName"
                :type="messageTypeName"
              >
                <b-input
                  v-model="name"
                  v-on:focusout.native="ValidityName"
                  v-on:focusin.native="clearValidityName"
                  id="name"
                  autocomplete="off"
                ></b-input>
              </b-field>
              
              <!-- START -->
              <div class="date-course is-flex">
                <b-field label="Fecha de inicio">
                  <b-datepicker
                      v-model="startcourseSelected"
                      :show-week-number="showWeekNumber"
                      :locale="locale"
                      placeholder="Seleccionar fecha"
                      icon="calendar-today"
                      :icon-right="startcourseSelected ? 'close-circle' : ''"
                      icon-right-clickable
                      trap-focus
                      :min-date="minDateStartCourse"
                      :max-date="maxDateStartCourse">
                  </b-datepicker>
                </b-field>

                <!-- END -->
                <b-field
                  class="end-date"
                  label="Fecha final">

                  <b-datepicker
                      v-model="endcourseSelected"
                      :show-week-number="showWeekNumber"
                      placeholder="Seleccionar fecha"
                      icon="calendar-today"
                      :icon-right="endcourseSelected ? 'close-circle' : ''"
                      icon-right-clickable
                      trap-focus
                      :min-date="minDateEndCourse">
                  </b-datepicker>
                </b-field>
              </div>

              <div class="pricing-students">

                <!-- PRICING -->
                <b-field
                  class="pricing"
                  label="Precio por clase">

                  <b-input
                    v-model="classprice"
                    type="number"
                    placeholder="EUR">
                  >
                  </b-input>
                </b-field>

                <!-- MAX STUDENTS -->
                <b-field
                  class="max-student"
                  label="Max de estudiantes">
                  
                  <b-input
                    placeholder="20"
                    type="number"
                    v-model="maxstudents"
                      >
                  </b-input>
                </b-field>
              </div>

              <div class="mt-5 coursetype-container">
                <!-- PRICING -->
                <b-field>
                  <b-radio v-model="coursetype"
                    name="coursetype"
                    style="color: #363636; font-size: 15px !important;"
                    native-value="groupcourse"
                    checked>
                      Grupo de clases
                  </b-radio>

                  <b-radio v-model="coursetype"
                    class="ml-3"
                    name="coursetype"
                    style="color: #363636;font-size: 15px !important;"
                    native-value="campamento">
                    Campamento
                  </b-radio>
                </b-field>
                
                <b-field
                  class="pricing-classes mt-5"
                  label="Precio por bono de 3, 5 y 10 clases"
                  v-if="coursetype == 'groupcourse'">

                  <b-input
                    v-model="classprice3"
                    type="number"
                    class="mr-2"
                    placeholder="3 clases">
                  >
                  </b-input>

                  <b-input
                    v-model="classprice5"
                    type="number"
                    class="mr-2"
                    placeholder="5 clases">
                  >
                  </b-input>

                  <b-input
                    v-model="classprice10"
                    type="number"
                    placeholder="10 clases">
                  >
                  </b-input>
                </b-field>

                <b-field
                  class="pricing mt-5"
                  label="Precio por 1, 2, 3 o 4 semanas"
                  v-if="coursetype == 'campamento'">

                  <b-input
                    v-model="weekprice1"
                    type="number"
                    class="mr-2"
                    placeholder="1 semana">
                  >
                  </b-input>

                  <b-input
                    v-model="weekprice2"
                    type="number"
                    class="mr-2"
                    placeholder="2 semanas">
                  >
                  </b-input>

                  <b-input
                    v-model="weekprice3"
                    type="number"
                    class="mr-2"
                    placeholder="3 semanas">
                  >
                  </b-input>
                  <b-input
                    v-model="weekprice4"
                    type="number"
                    placeholder="4 semanas">
                  >
                  </b-input>
                </b-field>
              </div>

              <div class="mentor-place">
  
                <!-- MENTOR -->
                <b-field class="mentor" label="Mentor">
                  <b-select 
                    v-model="profesorSelected" 
                    placeholder="Seleccionar">
                    <option
                      
                      v-for="(profesor, idx) in profesores"
                      :value="profesor._id"
                      :key="idx"> {{ profesor.firstname + ' ' + profesor.lastname }}
                    </option>
                  </b-select>
                </b-field>

                <!-- PLACE -->
                <b-field class="place ml-2" label="Lugar de clase">
                  <b-select 
                    v-model="locationSelected" 
                    placeholder="Seleccionar">
                    <option
                      
                      v-for="(location, idx) in locations"
                      :value="location._id"
                      :key="idx"> {{ location.name}}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <!-- DESCUENTO FEDERADOS Y RESIDENTES -->
              <div class="discount">
                <b-field label="Descuento">
                  <b-select 
                      v-model="typeSelected" 
                      placeholder="Seleccionar">
                      <option value="Residente">
                        Residente
                      </option>
                      <option value="Federado">
                        Federado
                      </option>
                  </b-select>
                </b-field>
                <b-field class="ml-4" label="Percentaje"
                :message="mensajeDescuento"
                :type="typeSelected && !discount ? 'is-primary' : ''">
                  <b-input
                      v-model="discount"
                      type="number"
                      placeholder="DCT %"
                      :disabled="typeSelected == ''"
                      v-on:focusout.native="ValidityDescuento"
                      v-on:focusin.native="clearValidityDescuento"
                    >
                    </b-input>
                </b-field>
              </div>

              <!-- CONDICIONES DEL CURSO -->
              <b-field
                class="mt-3"
                label="Condiciones">
                <b-input
                  v-model="conditions"
                  type="textarea"
                  placeholder="Los condiciones del Curso"></b-input>
              </b-field>
            </div>

            <div class="right">
              
              <!-- ESTADO -->
              <!-- <b-field label="Estado del curso">
                <b-select
                  v-model="status" 
                  placeholder="Seleccionar">
                  <option value=true> Activo </option>
                  <option value=false> Inactivo </option>
                </b-select>
            
              </b-field> -->

              <!-- NIVEL -->
              <b-field class="level-course" label="Nivel">
                <b-select 
                  v-model="skillLevelSelected" 
                  placeholder="Seleccionar">
                  <option
                    
                    v-for="(level, idx) in skillLevels"
                    :value="level"
                    :key="idx"> {{ level }}
                  </option>
                </b-select>
              </b-field>

            </div>
          </div>
      </div>
    </div>

    <div class="container-buttons">
      <button class="button-medium-stroke-black">Cancelar</button>
      <button
        @click="newCourse"
        class="button-primary-extra-medium ml-2">Guardar</button>
    </div>
  </div>


</template>

<script>

import CourseService from '@/services/CourseService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'NewCourse',
  components: {
  },
  data () {
    return {
      minDateStartCourse: new Date(),

      allProfiles: this.$store.state.allProfiles,
      locations: this.$store.state.locations,
      // status: null,
      profesorSelected: null,
      locationSelected: null,
      startcourseSelected: new Date(),
      endcourseSelected: null,


      name: null,
      
      classprice: null,
      classprice3: null,
      classprice5: null,
      classprice10: null,

      weekprice1: null,
      weekprice2: null,
      weekprice3: null,
      weekprice4: null,

      maxstudents: null,
      skillLevels: this.$store.state.skilllevels,
      skillLevelSelected: null,
      conditions: null,

      mensajeName: '',
      mensajeDescuento: '',
      mensajeClassprice: '',
      mensajeMaxstudents: '',

      messageTypeName: '',
      messageTypeClassprice: '',
      messageTypeMaxstudents: '',

      valid: true,
      mensaje: '',
      passwordError: null,
      generalError: null,
      loader: false,
      bindProps: {
        mode: 'international',
        preferredCountries: ['ES'],
        onlyCountries: [],
        ignoredCountries: []
      },
      showWeekNumber: true,
      locale: undefined,
      typeSelected: '',
      discount: undefined,
      coursetype: 'groupcourse'
    }
  },

  computed:  {
    profesores: function () {
      return this.allProfiles.filter(profile => profile.isMentor && profile.status === 'active')
    },
    maxDateStartCourse: function () {
      if (this.endcourseSelected) {
        return this.endcourseSelected
      } else {
        let now = new Date()
        let year = now.getFullYear();
        let month = now.getMonth();
        let day = now.getDate();
        let oneYearFormNow = new Date(year + 1, month, day) 
        return oneYearFormNow
      }
    },
    minDateEndCourse: function () {
      if (this.startcourseSelected) {
        return this.startcourseSelected
      } else {
        let now = new Date()
        let year = now.getFullYear();
        let month = now.getMonth();
        let day = now.getDate();
        let oneYearFormNow = new Date(year + 1, month, day) 
        return oneYearFormNow
      }
    },
  },

  methods: {

    // Close modal
    closeModal () {
      this.$store.commit('closeModal')
    },

    // Sign Up with Password --------
    async newCourse () {
      if (this.name && this.classprice && this.maxstudents) {
        this.valid = true
      }
      await this.ValidityName()
      await this.ValidityClassprice()
      await this.ValidMaxstudents()
      await this.ValidityDescuento()

      if (this.valid) {
        // console.log('--------------------- hhhhhh99')
        // this.loader = true
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const obj = {
          name: this.name,
          classprice: this.classprice,
          classprice3: this.classprice3,
          classprice5: this.classprice5,
          classprice10: this.classprice10,
          weekprice1: this.weekprice1,
          weekprice2: this.weekprice2,
          weekprice3: this.weekprice3,
          weekprice4: this.weekprice4,
          coursetype: this.coursetype,
          maxstudents: this.maxstudents,
          skilllevel: this.skillLevelSelected,
          location: this.locationSelected,
          // active: this.status,
          startcourse: this.startcourseSelected,
          endcourse: this.endcourseSelected,
          mentor: this.profesorSelected,
          conditions: this.conditions,
          residentDiscount: this.typeSelected == "Residente" && this.discount > 0 ? this.discount : 0,
          federadoDiscount: this.typeSelected == "Federado" && this.discount > 0 ? this.discount : 0,
        }

        console.log('-----7676', obj)

        const newCourse = await CourseService.NewCourse( idToken, obj)

        if (newCourse.status === 201) {
          this.$store.commit('closeModal')
          await firebase.auth().currentUser.getIdToken(true)
            .then(async idToken => {
              await CourseService.GetCourses(idToken)
                .then(response => {
                  if (response.status === 200) {
                    this.$store.commit('updateAllCourses', response.data)
                  }
                })
                .catch(()=>{})
            })

        }

      }
    },
    ValidityName () {
      if (!this.name) {
        this.mensajeName = 'Cuál es el nombre del curso'
        this.messageTypeName = 'is-primary'
        this.valid = false
      } else {
        this.mensajeName = ''
      }
    },
    ValidityDescuento() {
      if (this.typeSelected !== "" && !this.discount) {
        this.mensajeDescuento = 'Elige un descuento para el tipo elegido'
        this.valid = false
      } else this.mensajeDescuento = ''
    },
    ValidityClassprice () {
      if (!this.classprice) {
        this.mensajeClassprice = 'Cuál es el precio de cada clase del curso'
        this.messageTypeClassprice = 'is-primary'
        this.valid = false
      } else {
        this.mensajeClassprice = ''
      }
    },
    ValidMaxstudents () {
      if (!this.maxstudents) {
        this.mensajeMaxstudents = 'Cuál es el número máximo de estudiantes'
        this.messageTypeMaxstudents = 'is-primary'
        this.valid = false
      } else {
        this.mensajeMaxstudents = ''
      }
    },
    

    clearValidityName () {
      this.messageTypeName = ''
      this.mensajeName = ''
    },

    clearValidityDescuento() {
      this.mensajeDescuento = ''
    },

    clearValidityClassprice () {
      this.messageTypeClassprice = ''
      this.mensajeClassprice = ''
    },

    clearValidityMaxstudents () {
      this.messageTypeMaxstudents = ''
      this.mensajeMaxstudents = ''
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    }
  },

  created() {
    this.$store.commit("updateSkills")
  }
}


</script>


<style scoped>

.header-modal {
  width: 100%;
  background-color: #EFEFEF;
  height: 72px;
}

.container-header-modal {
  padding-top: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-modal h3 {
  font-size: 18px;
}

.form-create-course {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 50px;
  border-radius: 2px;
  padding-bottom: 32px;
}

.principal-info-course {
  width: 90%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ECECEC;
}

.status-course {
  text-align: right;
}

.status-course p {
  font-size: 14px;
}

.small-text {
  font-size: 12px;
}

.info-course {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.container-form {
  display: flex;
  width: 80%;
  margin-top: 40px;
  justify-content: space-between;
}

.letf {
  max-width: 410px;
}

.name-course {
  min-width: 270px;
  max-width: 385px;
}

.date-course .discount {
  display: flex;
}

.end-date {
  margin-left: 10px;
}

.max-student {
  margin-left: 7px;
}

.pricing-students {
  display: flex;
}

.mentor-place {
  display: flex;
  margin-top: 24px;
}

.place {
  margin-left: 10px;
}

.container-buttons {
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 60px;
}

.discount {
  display: flex;
  margin-top: 15px;
}


@media only screen and (max-width: 1120px) {
  .letf {
    max-width: 390px;
    margin-right: 10px;
  }

  .rigth {
    min-width: 239px;
  }

  .container-form {
    width: 100%;
  }
}


@media only screen and (max-width: 871px) {
  .container-form {
    display: block;
    width: 90%;
    margin: auto;
  }

  .right {
    display: flex;
    margin-top: 8px;
  }

  .level-course {
    margin-left: 0px;
    margin-top: 8px;
  }

  .discount {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 607px) {

  .form-create-course {
    width: 85%;
  }
}


@media only screen and (max-width: 513px) {

  .container-header-modal {
    width: 90%;
  }

  .container-header-modal h3 {
    font-size: 16px;
  }

  .form-create-course {
    width: 95%;
  }

  .button-close {
    width: 18px;
    height: 18px;
  }


  .no-mobile {
    display: none;
  }

  .date-course .discount {
    display: block;
  }

  .end-date {
    margin-left: 0px;
  }
  
  .max-student {
    margin-left: 0px; 
  }

  .pricing-students {
    display: block;
    margin-top: 12px;
  }

  .mentor-place {
    flex-direction: column;
    margin-top: -7px;
  }

  .place {
    margin-left: 0px !important;
    margin-top: 5px;
  }

  .date-course {
    flex-direction: column;
  }


  .coursetype-container {
    margin-top: 30px !important;
  }

  .pricing-classes {
    margin-top: 30px !important;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .form-create-course {
    width: 93%;
    margin-top: 15px;
  }

  .header-modal {
    height: 60px;
  }

  .letf {
    width: 100%;
    margin-right: 0px;
  }

  .container-buttons {
    width: 90%;
    padding-bottom: 30px;
    margin-top: 20px;
  }

}
</style>
