<template>
  <div id="perfil">
    <div class="container-profile">
      <div
        class="info-profile"
        v-if="$store.getters.currentProfile">

          <div class="image-profile">
            <img
              class="is-rounded"
              :src="$store.getters.currentProfile.avatar"
              width=200
            >
          </div>

          <div class="info-user">
            <div class="">
              <p class="montserrat-bold black">
                {{$store.getters.currentProfile.firstname}} {{$store.getters.currentProfile.lastname}}</p>

              <a
                @click.prevent="uploadImageModal"
                class="text-underline montserrat-bold link-change-picture">
                Cambiar foto de perfil
              </a>
            </div>

            <div class="status-profile">
              <p
                class="OpenSans-Bold gray-dark small title-status">
                ESTADO  DEL PROFESOR</p>
              <p
                class="OpenSans-Bold orange text-tranformation-uppercase ">
                {{$store.getters.currentProfile.status.charAt(0).toUpperCase() + $store.getters.currentProfile.status.slice(1)}}</p>
            </div>

          </div>
      </div>

      <div>
        <!-- Signup Form -->
        <form class="form-profile">

          <div class="name-user mt-5">
            <!-- Firstname -->
            <b-field
              class="names-field"
              label="Nombre(s)"
              :message="mensajeFirst"
              :type="messageTypeFirst"

            >
              <b-input
                v-on:focusout.native="ValidityFirstname"
                v-on:focusin.native="clearValidityFirst"
                id="firstname"
                v-model="firstname"
                autocomplete="off"
              >
              </b-input>
            </b-field>

            <!-- Lastname -->
            <b-field
              class="last-name names-field"
              label="Apellido(s)"
              :message="mensajeLast"
              :type="messageTypeLast"
            >
              <b-input
                v-on:focusout.native="ValidityLastname"
                v-on:focusin.native="clearValidityLast"
                id="lastname"
                v-model="lastname"
                autocomplete="off"
              >
              </b-input>
            </b-field>
          </div>

          <div class="date-information">
            
            <!-- date of birth -->
            <b-field
              class="date-of-birth mt-3"
              label="Fecha de nacimiento"
              :message="mensajeDateofbirth"
              :type="messageTypeDateofbirth"
            >

              <b-datepicker
                v-model="dateofbirth"
                placeholder="Agrega fecha..."
                
                :locale="locale"
                trap-focus
                editable
              >
              </b-datepicker>
            </b-field>

            <!-- eMail -->
            <b-field
              class="email"
              label="Correo Electrónico3"
              :message="mensajeEmail"
              :type="messageTypeEmail"
            >
              <b-input
                class="input-email"
                v-on:focusout.native="ValidityEmail"
                v-on:focusin.native="clearValidityEmail"
                :validation-message= mensaje
                id="email"
                placeholder="correo@algo.com"
                v-model="email"
                autocomplete="off"
              >
              </b-input>
            </b-field>

          </div>

          <div class="phone-information">
            <!-- phone -->
            <b-field
              class="mt-4 celphone"
              label="Teléfono móvil"
              :message="mensajePhone"
              :type="messageTypePhone"
              v-on:focusin.native="clearValidityPhone"
              autocomplete="off"
              >
              <vue-tel-input
                v-model="phone"
                v-bind="bindProps"
                id="phone"
                autocomplete="off"
                >
                </vue-tel-input>
            </b-field>

            <!-- lenguage -->
            <b-field
              class="mt-1 language"
              label="Idioma"
              :message="mensajeLang"
              :type="messageTypeLang"
            >
              <b-select
                v-model="supportlang"
                placeholder="Elige"
                expanded
                v-on:focusin.native="clearValidityLang"
                autocomplete="off"
              >
                <option value="Español">Español</option>
                <option value="Inglés">Inglés</option>
              </b-select>
            </b-field>

          </div>

          <div class="social-media mt-6">
            <p
            class="montserrat-bold black">Redes sociales</p>

            <div class="form-social is-flex is-align-items-center mt-5">
              <svg width="14" height="11" viewBox="0 0 14 11" fill="none" >
                <path d="M14 0.00588424C13.3906 0.401149 12.7159 0.703464 12.0018 0.901186C11.6186 0.495966 11.1092 0.208756 10.5427 0.0784014C9.9761 -0.0519532 9.37968 -0.0191633 8.83405 0.172336C8.28842 0.363836 7.81992 0.704805 7.4919 1.14913C7.16388 1.59345 6.99217 2.11969 7 2.65668V3.24184C5.88168 3.26851 4.77354 3.04044 3.77428 2.57794C2.77502 2.11545 1.91566 1.43289 1.27273 0.591048C1.27273 0.591048 -1.27273 5.85753 4.45455 8.19818C3.14397 9.01622 1.58274 9.4264 0 9.36851C5.72727 12.2943 12.7273 9.36851 12.7273 2.63912C12.7267 2.47613 12.7096 2.31353 12.6764 2.15344C13.3258 1.56447 13.7842 0.82085 14 0.00588424Z" fill="#C7C8D1"/>
              </svg>


              <b-field
              class="ml-3"
              :message="mensajeFirst"
              :type="messageTypeFirst"

              >
                <b-input
                class="social-media-input"
                placeholder="Url"
                id="twitter"
                v-model="twitter"
                autocomplete="off"
              >
                </b-input>
              </b-field>
            </div>

            <div class="form-social is-flex is-align-items-center mt-3">
              
              <svg width="11" height="17" viewBox="0 0 11 17" fill="none">
              <path d="M10 1H7.54545C6.46048 1 5.41994 1.39509 4.65275 2.09835C3.88555 2.80161 3.45455 3.75544 3.45455 4.75V7H1V10H3.45455V16H6.72727V10H9.18182L10 7H6.72727V4.75C6.72727 4.55109 6.81347 4.36032 6.96691 4.21967C7.12035 4.07902 7.32846 4 7.54545 4H10V1Z" fill="#C7C8D1" stroke="#C7C8D1" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <b-field
              class="ml-4"
              :message="mensajeFirst"
              :type="messageTypeFirst"

              >
                <b-input
                class="social-media-input"
                placeholder="Url"
                id="facebook"
                v-model="facebook"
                autocomplete="off"
              >
                </b-input>
              </b-field>
            </div>


            <div class="form-social is-flex is-align-items-center mt-3">
              
              <svg width="15" height="17" viewBox="0 0 15 17" fill="none" >
                <path d="M7.83155 2.31684C7.80481 3.03877 7.78476 5.5254 7.78476 7.8516V12.0695L7.61765 12.4104C7.41043 12.8249 6.95588 13.2594 6.52807 13.4532C6.0869 13.6537 5.2647 13.6537 4.8369 13.4599C4.38904 13.2527 4.00802 12.8783 3.77406 12.4171C3.39973 11.6618 3.52005 10.7193 4.07487 10.1043C4.23529 9.93048 4.52273 9.69652 4.72326 9.58957C5.04412 9.40909 5.13101 9.38904 5.71925 9.38904H6.36765L6.41444 8.06551C6.44117 7.3369 6.44117 6.72193 6.41444 6.69519C6.32085 6.6016 4.95053 6.6484 4.50936 6.76872C2.98529 7.16979 1.71524 8.35294 1.18717 9.85695C1.02005 10.3382 1.00668 10.4786 1 11.2941C1 12.1096 1.02005 12.25 1.19385 12.7914C1.67513 14.2553 2.95856 15.4719 4.44251 15.8797C5.02406 16.0401 6.24732 16.0401 6.84224 15.8797C8.14572 15.5254 9.28208 14.5896 9.85695 13.3997C10.3115 12.4439 10.3249 12.3369 10.3717 9.04813L10.4118 6.10027L10.8529 6.36096C11.6885 6.84893 12.9184 7.21658 13.7072 7.21658H14.0013V5.99332V4.76337L13.4198 4.64305C11.7286 4.28877 10.6524 3.21925 10.3382 1.56818L10.2246 1H9.04813H7.87166L7.83155 2.31684Z" fill="#C7C8D1" stroke="#C7C8D1" stroke-width="0.6"/>
              </svg>    

              <b-field
              class="ml-3"
              :message="mensajeFirst"
              :type="messageTypeFirst"

              >
                <b-input
                class="social-media-input"
                placeholder="Url"
                id="tiktok"
                v-model="tiktok"
                autocomplete="off"
              >
                </b-input>
              </b-field>
            </div>


            <div class="form-social is-flex is-align-items-center mt-3">
              
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 0H3.25C1.45507 0 0 1.45527 0 3.25044V9.75132C0 11.5465 1.45507 13.0018 3.25 13.0018H9.75C11.5449 13.0018 13 11.5465 13 9.75132V3.25044C13 1.45527 11.5449 0 9.75 0ZM6.79109 4.81959C6.41778 4.76422 6.03856 4.82871 5.70945 4.9995C5.38085 5.17003 5.12527 5.43408 4.96938 5.74384C4.81409 6.05241 4.76226 6.39619 4.81583 6.72915C4.86951 7.06275 5.02848 7.38105 5.28206 7.63468C5.53644 7.88909 5.87223 8.0638 6.24456 8.12372C6.61703 8.18366 6.99716 8.12391 7.32867 7.95703C7.65969 7.7904 7.91886 7.52919 8.07877 7.22096C8.23807 6.91392 8.29406 6.57061 8.24462 6.23718C8.19411 5.89651 8.034 5.57068 7.77535 5.31199C7.51585 5.05246 7.1718 4.87605 6.79109 4.81959ZM4.953 3.33091C5.59473 2.99788 6.32047 2.87754 7.02908 2.98263C7.75197 3.08984 8.42962 3.42775 8.95902 3.95722C9.48927 4.48754 9.84341 5.18355 9.95655 5.94663C10.0675 6.69527 9.93874 7.45707 9.59428 8.121C9.25045 8.78374 8.71257 9.30992 8.06661 9.63509C7.42114 9.96001 6.69412 10.0714 5.98673 9.9576C5.2792 9.84374 4.61742 9.50854 4.0984 8.98945C3.57859 8.46957 3.22772 7.79016 3.10762 7.04376C2.98743 6.29671 3.10677 5.53318 3.44322 4.86464C3.77906 4.1973 4.31076 3.6642 4.953 3.33091ZM10.0455 3.54582C10.3719 3.54582 10.6364 3.28122 10.6364 2.95483C10.6364 2.62844 10.3719 2.36384 10.0455 2.36384C9.71918 2.36384 9.45462 2.62844 9.45462 2.95483C9.45462 3.28122 9.71918 3.54582 10.0455 3.54582Z" fill="#C7C8D1"/>
              </svg>

              <b-field
              class="ml-4"
              :message="mensajeFirst"
              :type="messageTypeFirst"

              >
                <b-input
                class="social-media-input"
                placeholder="Url"
                id="instagram"
                v-model="instagram"
                autocomplete="off"
              >
                </b-input>
              </b-field>
            </div>


          </div>

          <div class="observations mt-6">
             <p
            class="montserrat-bold black">Descripción</p>

            <b-field class="mt-3" label="">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="observaciones"
              ></b-input>
            </b-field>

          </div>



          <!-- CTA Button -->
          <button class="button-primary-extra-medium mt-6 mb-6" @click.prevent="updateprofile">Guardar</button>


        </form>


      </div>
    </div>

  </div>
</template>

<script>
import ProfileService from '@/services/ProfileService'
import UserService from '@/services/UserService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'Perfil',
  components: {
    VueTelInput
  },
  data () {

    return {
      firstname: null,
      lastname: null,
      dateofbirth: null,
      email: null,
      phone: null,
      supportlang: null,
      twitter: null,
      facebook: null,
      tiktok: null,
      instagram: null,
      observaciones: null,

      mensajeEmail: '',
      mensajeFirst: '',
      mensajeLast: '',
      mensajePhone: '',
      mensajeDateofbirth: '',
      mensajeLang: '',
      

      messageTypeEmail: '',
      messageTypePhone: '',
      messageTypeFirst: '',
      messageTypeLast: '',
      messageTypeDateofbirth: '',
      messageTypeLang: '',

      valid: true,
      mensaje: '',
      generalError: null,
      loader: false,
      bindProps: {
        mode: 'international',
        preferredCountries: ['ES'],
        onlyCountries: [],
        ignoredCountries: []
      },
      locale: undefined, // Browser locale,
      date: new Date(),
      // maxDate: new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
    }
  },

  mounted () {
    this.$store.commit('updateCurrentProfile', window.location.pathname.split('/')[2])

    setTimeout(() => {
      this.firstname = this.$store.getters.currentProfile.firstname
      this.lastname = this.$store.getters.currentProfile.lastname
      this.email = this.$store.getters.currentProfile.email
      this.phone = this.$store.getters.currentProfile.phone
      this.supportlang = this.$store.getters.currentProfile.supportlang
      this.twitter = this.$store.getters.currentProfile.twitter
      this.facebook = this.$store.getters.currentProfile.facebook
      this.tiktok = this.$store.getters.currentProfile.tiktok
      this.instagram = this.$store.getters.currentProfile.instagram
      this.observaciones = this.$store.getters.currentProfile.observaciones
      if (this.$store.getters.currentProfile.dateofbirth) {
        this.dateofbirth = new Date(this.$store.getters.currentProfile.dateofbirth)
      }
    }, 1200)
    
  },

  methods: {

    // Upload image
    uploadImageModal () {
      this.$store.commit('openModal', 'uploadimage')
    },

   // Sign Up with Password --------
    async updateprofile () {

      let profileToSend = {
            firstname: this.firstname,
            lastname: this.lastname,
            dateofbirth: this.dateofbirth,
            phone: this.phone,
            supportlang: this.supportlang,
            twitter: this.twitter,
            facebook: this.facebook,
            tiktok: this.tiktok,
            instagram: this.instagram,
            observaciones: this.observaciones,
            _id: this.$store.state.currentProfileId
          }

      if (this.firstname && this.lastname && this.email && this.phone) {
        this.valid = true
      }
      await this.ValidityFirstname()
      await this.ValidityLastname()
      await this.validPhone()

      if (this.email !== this.$store.getters.currentProfile.email) {
        await this.ValidityEmail()
        profileToSend.email = this.email
        this.updateEmailFirebase(this.email)
      }
      
      await this.ValidityDateofbirth(this.email)
      await this.validLang()



      if (this.valid) {
        // console.log('--------------------- hhhhhh99')
        this.loader = true

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newProfile = await ProfileService.updateProfile( idToken, profileToSend)
        


        if (newProfile.status === 201) {
          this.success('Guardado')

          await firebase.auth().currentUser.getIdToken(true)
            .then(async idToken => {
              await ProfileService.getCurrentProfiles(idToken)
                .then(response => {
                  if (response.status === 200) {
                    // console.log('01', response)
                    this.$store.commit('updateProfiles', response.data)
                  }
                })
                .catch(()=>{})
            })

        }

      }
    },

    // Update Email in Firebase
    async updateEmailFirebase (emailFirebase) {
      const user = firebase.auth().currentUser
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await user.updateEmail(emailFirebase)
      await UserService.updateUser(idToken, {
        email: emailFirebase
      })
    },

    ValidityFirstname () {
      if (!this.firstname) {
        this.mensajeFirst = 'Cuál es el nombre'
        this.messageTypeFirst = 'is-primary'
        this.valid = false
      } else {
        this.mensajeFirst = ''
        // this.messageTypeFirst = 'is-success'
      }
    },
    ValidityLastname () {
      if (!this.lastname) {
        this.mensajeLast = 'Cuál es el apellido'
        this.messageTypeLast = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLast = ''
        // this.messageTypeLast = 'is-success'
      }
    },
    ValidityDateofbirth () {
      if (!this.dateofbirth) {
        this.mensajeDateofbirth = 'Cuál es la fecha de nacimiento'
        this.messageTypeDateofbirth = 'is-primary'
        this.valid = false
      } else {
        this.mensajeDateofbirth = ''
        // this.messageTypeLast = 'is-success'
      }
    },
    validPhone () {
      if (!this.phone) {
        this.mensajePhone = 'We\'ll send booking notifications to this phone number.'
        this.messageTypePhone = 'is-primary'
        document.getElementById('phone').classList.add('invalid-input')
        this.valid = false
      } else {
        this.mensajePhone = ''
        this.messageType = ''
      }
    },
    validLang () {
      if (!this.supportlang) {
        this.mensajeLang = 'No te olvides de elegir el idioma'
        this.messageTypeLang = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLang = ''
        this.messageTypeLang = ''
      }
    },
    async ValidityEmail () {
      try {
        const response = await firebase.auth().fetchSignInMethodsForEmail(this.email)
        if (response.length > 0) {
          this.mensajeEmail = 'Este correo ya está registrado'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else if (!this.validEmail(this.email)) {
          this.mensajeEmail = 'Algo está mal. Revisa to correo electrónico.'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else {
          this.mensajeEmail = ''
          // this.messageTypeEmail = 'is-success'
        }
      } catch (error) {
        this.mensajeEmail = error.message
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      }
    },

    clearValidityFirst () {
      this.messageTypeFirst = ''
      this.mensajeFirst = ''
    },
    clearValidityLast () {
      this.messageTypeLast = ''
      this.mensajeLast = ''
    },
    clearValidityPhone () {
      this.mensajePhone = ''
      document.getElementById('phone').classList.remove('invalid-input')
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },
    clearValidityLang () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-primary'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}


</script>


<style scoped>

.is-rounded {
  border-radius: 100px;
}

#perfil {
  background-color: #FAFAFA;;
  height: 127vh;
}

.container-profile {
  background-color: #fff;
  border: 1px solid #EDEDED;
  width: 70%;
  max-width: 882px;
  margin: auto;
  margin-left: 19%;
  margin-top: 100px;
  position: absolute;
  z-index: 1;
  border-radius: 0px;
}

.info-profile {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 27px;
  align-items: center;
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.image-profile img { 
  width: 62px;
  height: 62px;
}

.info-user {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.status-profile p {
  text-align: right;
}

.small {
  font-size: 12px;
}

.normal {
  font-size: 14px;
}

.form-profile {
  width: 70%;
  margin-left: 5%;
}

.names-field {
  min-width: 256px;
}

.name-user {
  display: flex;
}

.last-name {
  margin-left: 10px;
}

.date-information {
  display: flex;
  align-items: center;
}

.input-email {
  min-width: 320px;
}

.email {
  margin-left: 10px;
}

.phone-information {
  display: flex;
  align-items: center;
}

.language {
  min-width: 300px;
  margin-left: 10px;
}

.celphone {
  min-width: 210px;
}

.social-media-input {
  min-width: 353px;
}

.date-of-birth {
  min-width: 174px;
}

@media only screen and (max-width: 974px) {

  .date-of-birth {
    min-width: 164px;
  }

  .names-field {
    min-width: 205px;
  }

  .input-email {
    min-width: 202px;
    max-width: 193px;
  }

  .email {
    min-width: 203px;
    margin-left: 10px;
  }

  .language {
    margin-left: 10px;
    min-width: 204px;
  }

  .celphone {
    max-width: 206px;
    min-width: 161px;
  }
  
}



@media only screen and (max-width: 785px) {
  .form-profile {
    width: 74%;
    margin: auto;
    margin-top: 48px;
  }

  .date-of-birth {
    width: 185px;
  }
}


@media only screen and (max-width: 698px) {
  .container-profile {
    width: 90%;
    margin-left: 5%;
  }

  .info-user {
    width: 97%;
    margin-left: 1%;
    margin: auto;
    margin-top: 12px;
  }

  .info-profile {
    display: block;
  }

  .title-status {
    display: none;
  }

  .status-profile p {
    font-size: 14px;
  }

  .link-change-picture {
    font-size: 12px;
  }

  .form-profile {
    width: 87%;
    margin-top: 48px;
  }

  .name-user {
    display: block;
  }

  .last-name {
    margin-left: 0px;
  }

  .names-field {
    width: 90%;
    min-width: 285px;
    max-width: 310px;
  }

  .date-information {
    display: block;
  }

  .date-of-birth {
    width: 90%;
    min-width: 285px;
    max-width: 310px;
  }

  .email {
    margin: auto;
    margin-left: 0px;
  }

  .input-email {
    width: 90%;
    
    min-width: 285px;
    max-width: 310px;
  }

  .phone-information {
    display: block;
  }

  .celphone {
    width: 90%;
    min-width: 285px;
    max-width: 310px;
  }

  .language {
    width: 90%;
    min-width: 285px;
    max-width: 310px;
    margin-left: 0px;
  }

  .social-media-input {
    width: 60%;
    min-width: 252px;
    max-width: 310px;
  }

  .observations {
    width: 88%;
    min-width: 285px;
    max-width: 310px;
    margin-left: 0px;
  }

  
}

</style>
