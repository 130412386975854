var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"student"}},[_c('div',{staticClass:"container-student"},[(_vm.section === 'mis-perfiles')?_c('p',{staticClass:"montserrat-medium black title-step"},[_vm._v("4. Elige un estudiante")]):_vm._e(),(_vm.section === 'cursos-activos')?_c('p',{staticClass:"montserrat-medium black title-step"},[_vm._v("2. Elige un estudiante")]):_vm._e(),_c('div',{staticClass:"list-student"},_vm._l((_vm.profiles),function(profile,index){return _c('div',{key:index,staticClass:"content-student is-flex is-align-items-center",class:{
          bookingSelection:
            _vm.$store.state.weeklyBookingState.student &&
            _vm.$store.state.weeklyBookingState.student == profile,
          disabled:
            false//$store.getters.currentClass.bookings.some(booking => booking.profile_id === profile._id),
        },on:{"click":function($event){return _vm.saveStudentSelection(profile)}}},[_c('img',{attrs:{"src":profile.avatar}}),(!(profile.residente && _vm.$store.state.weeklyBookingState.course.residentDiscount > 0 || profile.federado && _vm.$store.state.weeklyBookingState.course.federadoDiscount))?_c('p',{staticClass:"montserrat-semibold black ml-3"},[_vm._v(" "+_vm._s(profile.firstname + " " + profile.lastname)+" ")]):_c('p',{staticClass:"montserrat-semibold black ml-3"},[_vm._v(" "+_vm._s(profile.firstname + " " + profile.lastname)+" "+_vm._s('(' + _vm.calculateDiscount(_vm.$store.state.weeklyBookingState.course, profile) + '% descuento)')+" ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }